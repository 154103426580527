import { useTranslation } from 'react-i18next';

export const useGetRole = () => {
  const { t } = useTranslation();

  const getRole = (role: string, count = 1) => {
    role = role.toLowerCase();
    const noun = count > 1 ? 'plural' : 'singular';
    return t(`user.role.${role}.${noun}`);
  };

  return getRole;
};
