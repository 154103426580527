import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useState } from 'react';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { useTranslation } from 'react-i18next';
import { Box, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

type Day = {
  abbreviation: string;
  full: string;
};

export default function TemplateDialog({ open, onClose, onSaveTemplate }) {
  const [name, setName] = useState<string>('');
  const [note, setNote] = useState<string>('');
  const [checkboxes, setCheckboxes] = useState<any>([]);

  const days: Day[] = [
    { abbreviation: 'Mon', full: 'monday' },
    { abbreviation: 'Tue', full: 'tuesday' },
    { abbreviation: 'Wed', full: 'wednesday' },
    { abbreviation: 'Thu', full: 'thursday' },
    { abbreviation: 'Fri', full: 'friday' },
    { abbreviation: 'Sat', full: 'saturday' },
    { abbreviation: 'Sun', full: 'sunday' },
  ];

  const { t } = useTranslation();

  const handleCheckboxChange = (day: string) => {
    if (checkboxes.includes(day)) {
      setCheckboxes(checkboxes.filter(d => d !== day));
    } else {
      setCheckboxes([...checkboxes, day]);
    }
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();

    if (name) {
      // Convert the selected days to full names in lowercase
      const selectedFullDays = checkboxes.map(
        day => days.find(d => d.abbreviation === day)?.full,
      );

      const formData = {
        name,
        note,
        days: selectedFullDays,
      };

      onSaveTemplate(formData);
      onClose(true);
      setName('');
      setCheckboxes([]);
    }
  };

  const handleClose = () => {
    onClose(true);
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs">
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 20,
          top: 12,
          color: 'gray',
        }}
      >
        <CloseIcon />
      </IconButton>

      <DialogTitle sx={{ fontSize: '1.42rem' }}>
        {t('orders.actions.save_as_template')}
      </DialogTitle>
      <DialogContent>
        <Box component="form" noValidate autoComplete="off">
          <TextField
            id="template-name"
            label={t('orders.template.input.name')}
            placeholder={t('orders.template.input.name_placeholder')}
            inputProps={{ maxLength: 40 }}
            onChange={e => setName(e.target.value)}
            required
            variant="outlined"
            fullWidth
            error={name === ''}
            helperText={
              name === '' ? t('orders.template.input.name_is_required') : ''
            }
          />
          <TextField
            id="template-note"
            label={t('orders.template.input.note')}
            placeholder={t('orders.template.input.note_placeholder')}
            onChange={e => setNote(e.target.value)}
            variant="outlined"
            fullWidth
            multiline
            rows={2.5}
          />
          <div>
            <Typography
              sx={{
                fontFamily: 'Roboto',
                fontSize: '1.143rem',
                fontWeight: 400,
                lineHeight: '28px',
                letterSpacing: '0.15px',
                textAlign: 'left',
              }}
            >
              {t('orders.template.input.set_for')}:
            </Typography>
            <div style={{ marginLeft: '15px' }}>
              {days.map(day => (
                <FormControlLabel
                  key={day.abbreviation}
                  control={
                    <Checkbox
                      checked={checkboxes.includes(day.abbreviation)}
                      onChange={() => handleCheckboxChange(day.abbreviation)}
                    />
                  }
                  label={t(day.abbreviation)}
                />
              ))}
            </div>
          </div>
        </Box>
      </DialogContent>
      <Divider variant="middle" sx={{ margin: 0 }} />
      <DialogActions>
        <Button
          onClick={onClose}
          color="primary"
          sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          variant="contained"
          sx={{
            borderRadius: '42px',
            mr: '12px',
          }}
        >
          {t('common.buttons.save')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
