import { useTranslation } from 'react-i18next';
import { ManagedTooltip } from '../../TableComponent/helpers/Components/ManagedTooltip';
import { ContainerCollected } from '../../../pages/AddRoundTrips/data/RoundTripData/components/RouteData';
import { Icon } from 'app/components/Icon';
import { Ruler, Scales } from '@phosphor-icons/react';
import If from 'app/components/If';

interface ConstraintsProps {
  weight?: number;
  volume?: number;
  tooltipDisabled?: boolean;
}

export default function Constraints({
  weight,
  volume,
  tooltipDisabled = false,
}: ConstraintsProps) {
  const { t } = useTranslation();
  return (
    <ContainerCollected>
      <If condition={!!weight || weight === 0}>
        <div className="row">
          <div>
            <ManagedTooltip
              title={t('roundtrips.tooltip.weight')}
              disabled={!!tooltipDisabled}
            >
              <span>
                <Icon icon={<Scales />} />
              </span>
            </ManagedTooltip>
          </div>
          <p>{weight?.toFixed(2) || 'N/A'} kg</p>
        </div>
      </If>
      <If condition={!!volume || volume === 0}>
        <div className="row">
          <div>
            <ManagedTooltip
              title={t('roundtrips.tooltip.volume')}
              disabled={!!tooltipDisabled}
            >
              <span>
                <Icon icon={<Ruler />} />
              </span>
            </ManagedTooltip>
          </div>
          <p>{volume || 0} ㎥</p>
        </div>
      </If>
    </ContainerCollected>
  );
}
