import { IconButton } from '@mui/material';
import { ArrowCircleLeft, ArrowCircleRight } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';
import If from 'app/components/If';
import { useContext } from 'react';
import { LeftMenuContext } from '../..';

export const CollapseButton = () => {
  const { collapsed, setCollapsed } = useContext(LeftMenuContext);
  return (
    <IconButton
      onClick={() => setCollapsed(prev => !prev)}
      disableRipple
      sx={{ padding: '8px 0' }}
    >
      <If
        condition={!!collapsed}
        otherwise={<Icon icon={<ArrowCircleLeft />} size={20} />}
      >
        <Icon icon={<ArrowCircleRight />} size={20} />
      </If>
    </IconButton>
  );
};
