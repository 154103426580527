import { Dispatch, SetStateAction } from 'react';
import { HeadCell } from '../excelHeadCellsConst';
import { Dayjs } from 'dayjs';

export type Order = 'asc' | 'desc';

export enum RoundtripsType {
  Assigned = 'assigned',
  Unassigned = 'unassigned',
  Sales = 'sales',
  Logistic = 'logistic',
  Drivers = 'drivers',
}
export interface EnhancedTableToolbarProps {
  allRoundtrip?: any;
  roundTripCopy?: any;
  selectedWarehouse?: any;
  viewMode?: boolean;
  anchorEl?: any;
  setAnchorEl?: any;
  triggerByMode: any;
  roundTripsByMode: any;
  deleteFunction?: any;
  roundTripStatus?: any;
  updateRoundtripData?: any;
  bulkClear?: any;
  bulkDelete?: any;
  bulkChangeStatus?: any;
  anchorElUpload: any;
  handleClickUpload?: (event: React.MouseEvent<HTMLElement>) => void;
  setAnchorElUpload?: any;
  anchorElManual?: any;
  setAnchorElManual?: any;
  dateValue?: Dayjs | null;
  numSelected: number;
  search: string;
  setSearch: React.Dispatch<React.SetStateAction<string>>;
  headCells: HeadCell[];
  changeColumnAction: (
    id: any,
    value: any,
    headCells: any,
    setHeadCells: any,
  ) => void;
  clearAllAction: (headCells: any, setHeadCells: any) => void;
  selectAllAction: (headCells: any, setHeadCells: any) => void;
  heads: any[];
  setFilters: React.Dispatch<any>;
  setHeadCells: Dispatch<SetStateAction<HeadCell[]>>;
  name: string;
  begin: string;
  end: string;
  selected:
    | {
        key: string;
      }[]
    | React.Dispatch<
        React.SetStateAction<
          | []
          | {
              key: string;
            }[]
        >
      >;
  setSelected:
    | {
        key: string;
      }[]
    | React.Dispatch<
        React.SetStateAction<
          | []
          | {
              key: string;
            }[]
        >
      >;
}
