interface HeadCell {
  id: string;
  label: string;
  disablePadding: boolean;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  filter: boolean;
  key: string | null;
  sortField?: string;
  sortValue?: string;
  center?: boolean;
}

export const productsHeadCellsConst: HeadCell[] = [
  {
    id: 'code',
    label: 'product.heads.code',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'product.heads.code',
    sortValue: '_product.code',
  },
  {
    id: 'product',
    label: 'orderDetail.tab.item',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.item',
    sortValue: '_product.name',
  },
  {
    id: 'stock',
    label: 'orderDetail.tab.stock',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
  },
  // {
  //     id: 'boxingUnit',
  //     label: 'orderDetail.tab.boxingUnit',
  //     numeric: false,
  //     disablePadding: false,
  //     disableRow: false,
  //     canChangeView: false,
  //     filter: false,
  //     key: null,
  //     sortField: 'orderDetail.tab.boxingUnit',
  //     sortValue: '_product.boxingUnit'
  // },
  {
    id: 'quantity',
    label: 'orderDetail.tab.quantity',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.quantity',
    sortValue: 'quantity',
  },
  {
    id: 'loadedQuantity',
    label: 'orderDetail.tab.quantityLoaded',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.quantityLoaded',
    sortValue: 'quantityLoaded ',
  },
  {
    id: 'quantityDelivered',
    label: 'orderDetail.tab.quantityDelivered',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.quantityDelivered',
    sortValue: 'quantityDelivered ',
  },
  // {
  //     id: 'batch',
  //     label: 'orderDetail.tab.batch',
  //     numeric: false,
  //     disablePadding: false,
  //     disableRow: false,
  //     canChangeView: false,
  //     filter: false,
  //     key: null,
  // },
  {
    id: 'unitPrice',
    label: 'unit_currency',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'unit_currency',
    sortValue: 'unitPrice',
  },
  {
    id: 'price',
    label: 'price_currency',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'price_currency',
    sortValue: 'salePrice',
  },
  {
    id: 'discount',
    label: 'orderDetail.tab.discount',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.discount',
    sortValue: 'discount',
    center: true,
  },
  {
    id: 'totalHt',
    label: 'orderDetail.tab.totalHt',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
    sortField: 'orderDetail.tab.totalHt',
    sortValue: 'amount',
  },
  {
    id: 'notes',
    label: '',
    numeric: false,
    disablePadding: false,
    disableRow: false,
    canChangeView: false,
    filter: false,
    key: null,
  },
];
