import { ExpandedProps } from 'app/components/TableComponent/styles';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const SectionView = styled.div<ExpandedProps>(props => ({
  marginRight: '10px',
  marginLeft: '16px',
  display: 'flex',
  alignItems: 'center',
  '& .dropdown-text': {
    color: props.expanded === true ? props.theme.primary : props.theme.text,
    fontSize: '0.875rem',
  },

  '& .stats-dropdown-text': {
    color: themes?.default?.accordionWhiteBg,
    fontSize: '0.875rem',
  },
}));
