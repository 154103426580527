import React from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import styled from 'styled-components';

import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from 'app/pages/AddRoundTrips/components/TableComponent/styles/accordion';

export default function StopsList() {
  const theme = useSelector(selectTheme);
  const [accordionStatus, setAccordionStatus] = React.useState(false);

  return (
    <>
      <Accordion expanded={accordionStatus}>
        <AccordionSummary
          aria-controls="panel1d-content"
          id="panel1d-header"
          expandIcon={null}
          sx={{ height: 38, minHeight: 'unset', padding: 'unset' }}
        >
          <AccordionSummaryContent>
            <div style={{ width: 150 }}>
              <div
                onClick={event => {
                  event.stopPropagation();
                  setAccordionStatus(oldState => {
                    return !oldState;
                  });
                }}
                style={{ width: 'fit-content', cursor: 'pointer' }}
              >
                <span>Number of Stops</span>
                {accordionStatus ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
              </div>
            </div>
            <div>
              5
              <PersonPinCircleIcon sx={{ width: 18, color: theme.primary }} />
              + 1
              <LocalGasStationIcon sx={{ width: 18, color: theme.primary }} />
            </div>
          </AccordionSummaryContent>
        </AccordionSummary>
        <AccordionDetails sx={{ maxHeight: 303, overflowY: 'auto' }}>
          <div
            style={{
              display: 'flex',
              borderBottom: `0.063rem solid ${theme.borderGrey}`,
            }}
          >
            <StopTitle>Stop1</StopTitle>
            <StopDataWrapper>
              <div>
                <div className="time">[7:34am]</div>
                <div>
                  <DriverWrapper>
                    <DriverCode>C1000872-0 -</DriverCode>{' '}
                    <DriverName>Fahed Backery</DriverName>
                  </DriverWrapper>
                  <StopLocation>
                    France - paris , charle de gaulle 234dshfskdhfjdshfjdshfjdf
                  </StopLocation>
                </div>
                <CountDeliveryWrapper>
                  <div className="count">3</div>
                  <div className="delivery">Delivered at 7:14pm (-20min)</div>
                </CountDeliveryWrapper>
              </div>
            </StopDataWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: `0.063rem solid ${theme.borderGrey}`,
            }}
          >
            <StopTitle>Stop2</StopTitle>
            <StopDataWrapper>
              <div>
                <div className="time">[7:34am]</div>
                <div>
                  <DriverWrapper>
                    <DriverCode>C1000872-0 -</DriverCode>{' '}
                    <DriverName>Fahed Backery</DriverName>
                  </DriverWrapper>
                  <StopLocation>
                    France - paris , charle de gaulle 234dshfskdhfjdshfjdshfjdf
                  </StopLocation>
                </div>
                <CountDeliveryWrapper>
                  <div className="count">3</div>
                  <div className="delivery">Delivered at 7:14pm (-20min)</div>
                </CountDeliveryWrapper>
              </div>
            </StopDataWrapper>
          </div>
          <div
            style={{
              display: 'flex',
              borderBottom: `0.063rem solid ${theme.borderGrey}`,
            }}
          >
            <StopTitle>Stop3</StopTitle>
            <StopDataWrapper>
              <div>
                <div className="time">[7:34am]</div>
                <div>
                  <DriverWrapper>
                    <DriverCode>C1000872-0 -</DriverCode>{' '}
                    <DriverName>Fahed Backery</DriverName>
                  </DriverWrapper>
                  <StopLocation>
                    France - paris , charle de gaulle 234dshfskdhfjdshfjdshfjdf
                  </StopLocation>
                </div>
                <CountDeliveryWrapper>
                  <div className="count">3</div>
                  <div className="delivery">Delivered at 7:14pm (-20min)</div>
                </CountDeliveryWrapper>
              </div>
            </StopDataWrapper>
          </div>
        </AccordionDetails>
      </Accordion>
    </>
  );
}

const AccordionSummaryContent = styled.div(props => ({
  display: 'flex',
  height: 38,
  borderBottom: `0.063rem solid ${props.theme.borderGrey}`,
  alignItems: 'center',
  paddingLeft: 26,
  fontSize: '0.875rem',
  fontWeight: 700,
  width: '100%',
  cursor: 'auto',
}));

const StopDataWrapper = styled.div(props => ({
  width: '100%',
  height: 100,
  background: props.theme.primaryActive,
  paddingTop: '8px',
  overflow: 'hidden',
  fontSize: '0.625rem',
  '& .time': {
    color: props.theme.textSecondary,
  },
}));

const StopTitle = styled.div(props => ({
  minWidth: 170,
  height: 100,
  background: props.theme.primaryActive,
  display: 'flex',
  alignItems: 'center',
  paddingLeft: '26px',
  fontWeight: 700,
}));
const StopLocation = styled.div(props => ({
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
}));

const DriverWrapper = styled.div(props => ({
  display: 'flex',
  whiteSpace: 'nowrap',
}));

const DriverCode = styled.div(props => ({
  fontWeight: 700,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const DriverName = styled.span(props => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
}));

const CountDeliveryWrapper = styled.div(props => ({
  display: 'flex',
  columnGap: 6,
  marginTop: 10,
  '& .count': {
    display: 'flex',
    backgroundColor: props.theme.primaryLightO,
    borderRadius: 16,
    fontSize: '0.5625rem',
    color: props.theme.primary,
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.125rem 10px',
  },
  '& .delivery': {
    display: 'flex',
    backgroundColor: props.theme.success,
    borderRadius: 16,
    fontSize: '0.5625rem',
    color: props.theme.greenMain,
    alignItems: 'center',
    width: 'fit-content',
    padding: '0.125rem 10px',
  },
}));
