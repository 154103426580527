import { useMemo } from 'react';

interface DisabledToolbarProps {
  total: number;
  searchTerm: string;
  filterCount: number;
  extraCondition?: boolean;
}

export const useDisabledToolbar = ({
  total,
  searchTerm,
  filterCount,
  extraCondition = true,
}: DisabledToolbarProps) => {
  const disabledToolbar = useMemo(() => {
    return (
      total === 0 &&
      (!searchTerm || searchTerm === '') &&
      !filterCount &&
      extraCondition
    );
  }, [total, searchTerm, filterCount, extraCondition]);

  return { disabledToolbar };
};
