import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from '@mui/material';
import styled from 'styled-components';
import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';
interface Props {
  setSettingsDrawerIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Customization: React.FC<Props> = ({ setSettingsDrawerIsOpen }) => {
  const theme = useSelector(selectTheme);

  const { t } = useTranslation();
  const [menu, setMenu] = React.useState('full_menu');
  const [tiling, setTiling] = React.useState('single');

  const handleTilingChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTiling((event.target as HTMLInputElement).value);
  };
  const handleMenuChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMenu((event.target as HTMLInputElement).value);
  };
  const handleClose = () => {
    setSettingsDrawerIsOpen(false);
  };

  return (
    <form style={{ width: 455 }}>
      <EditUserTitle>
        <ArrowBackIcon
          sx={{ color: theme.icon, cursor: 'pointer' }}
          onClick={handleClose}
        />
        <div className="title">{t('nav.customization_side_bar.title')}</div>
      </EditUserTitle>
      <ResetAll>
        <span>{t('nav.customization_side_bar.reset_button')}</span>
      </ResetAll>
      <FormWrapper>
        <FormControl>
          <FormLabel
            sx={{ color: themes?.default?.black }}
            id="demo-controlled-radio-buttons-group"
          >
            {t('nav.customization_side_bar.sub_title_menu')}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={menu}
            onChange={handleMenuChange}
            sx={{ marginLeft: '11px' }}
          >
            <FormControlLabel
              value="full_menu"
              control={<Radio />}
              label={t('nav.customization_side_bar.label_full')}
            />
            <FormControlLabel
              value="admin_menu"
              control={<Radio />}
              label={t('nav.customization_side_bar.label_administration')}
            />

            <FormControlLabel
              value="dockers_menu"
              control={<Radio />}
              label={t('nav.customization_side_bar.label_docker')}
            />
            <FormControlLabel
              value="drivers menu"
              control={<Radio />}
              label={t('nav.customization_side_bar.label_driver')}
            />
          </RadioGroup>
        </FormControl>
        <FormControl>
          <FormLabel
            sx={{ color: themes?.default?.black }}
            id="demo-controlled-radio-buttons-group"
          >
            {t('nav.customization_side_bar.sub_title_tiling')}
          </FormLabel>
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            value={tiling}
            onChange={handleTilingChange}
            sx={{ marginLeft: '11px' }}
          >
            <FormControlLabel
              value="single"
              control={<Radio />}
              label={t('nav.customization_side_bar.label_single')}
            />
            <FormControlLabel
              value="mm"
              control={<Radio />}
              label="(M) + (M)"
            />

            <FormControlLabel
              value="ms"
              control={<Radio />}
              label="(M) + (SS + (RT + SS))"
            />
          </RadioGroup>
        </FormControl>
      </FormWrapper>
    </form>
  );
};

const EditUserTitle = styled.div`
  display: flex;
  margin: 18px;
  & img {
    cursor: pointer;
  }
  & .title {
    margin-left: 20px;
    font-weight: 500;
  }
`;

const ResetAll = styled.div(props => ({
  background: props.theme.lightGrey,
  height: 52,
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '16px 30px',
  '& span': {
    textDecoration: 'underline',
    cursor: 'pointer',
    fontSize: '0.875rem',
  },
}));

const FormWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 24px;
  margin-top: 28px;
`;
