import { ROUNDTRIP_STATUS } from 'app/components/RoundTrips/RoundTripData/StatusColors';
import styled from 'styled-components';
import { css } from 'styled-components';

const sharedStyles = css`
  align-items: center;
  padding: 2px 14px;
  width: fit-content;
  height: 22px;
  border-radius: 16px;
`;

export const RoundtripStatus = styled.div`
  .pending {
    color: ${ROUNDTRIP_STATUS.pending.color};
    background: ${ROUNDTRIP_STATUS.pending.background};
    ${sharedStyles}
  }
  .prepared {
    color: ${ROUNDTRIP_STATUS.prepared.color};
    background: ${ROUNDTRIP_STATUS.prepared.background};
    ${sharedStyles}
  }
  .loaded {
    color: ${ROUNDTRIP_STATUS.loaded.color};
    background: ${ROUNDTRIP_STATUS.loaded.background};
    ${sharedStyles}
  }
  .planned {
    color: ${ROUNDTRIP_STATUS.planned.color};
    background: ${ROUNDTRIP_STATUS.planned.background};
    ${sharedStyles}
  }
  .on-way {
    color: ${ROUNDTRIP_STATUS.on_way.color};
    background: ${ROUNDTRIP_STATUS.on_way.background};
    ${sharedStyles}
  }
  .completed {
    color: ${ROUNDTRIP_STATUS.completed.color};
    background: ${ROUNDTRIP_STATUS.completed.background};
    ${sharedStyles}
  }
  .on-dock {
    color: ${ROUNDTRIP_STATUS.on_dock.color};
    background: ${ROUNDTRIP_STATUS.on_dock.background};
    ${sharedStyles}
  }
  .unloaded {
    color: ${ROUNDTRIP_STATUS.unloaded.color};
    background: ${ROUNDTRIP_STATUS.unloaded.background};
    ${sharedStyles}
  }
  .unloading {
    color: ${ROUNDTRIP_STATUS.unloaded.color};
    background: ${ROUNDTRIP_STATUS.unloaded.background};
    ${sharedStyles}
  }
  .parked {
    color: ${ROUNDTRIP_STATUS.parked.color};
    background: ${ROUNDTRIP_STATUS.parked.background};
    ${sharedStyles}
  }
  .paused {
    color: ${ROUNDTRIP_STATUS.paused.color};
    background: ${ROUNDTRIP_STATUS.paused.background};
    ${sharedStyles}
  }
  .canceled {
    color: ${ROUNDTRIP_STATUS.canceled.color};
    background: ${ROUNDTRIP_STATUS.canceled.background};
    ${sharedStyles}
  }
  .loading {
    color: ${ROUNDTRIP_STATUS.loading.color};
    background: ${ROUNDTRIP_STATUS.loading.background};
    ${sharedStyles}
  }
`;
