import React, { useEffect } from 'react';

export const useCountRoundtrip = ({
  roundTripCopy,
  warehouse,
  allRoundtrip,
  setCountRoundtrip,
}) => {
  useEffect(() => {
    const foundObject = roundTripCopy?.find(
      obj => obj?._deck?._id === warehouse?._id,
    );

    if (!allRoundtrip && foundObject?._clusters?.[0]?._id) {
      setCountRoundtrip(2);
    } else if (allRoundtrip && foundObject) {
      setCountRoundtrip(2);
    } else {
      setCountRoundtrip(1);
    }
  }, [warehouse, roundTripCopy]);
};
