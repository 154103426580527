import {
  CountDeliveryWrapper,
  DriverCode,
  DriverRipperFields,
  StopDataWrapper,
  StopsDetailsDescription,
} from 'styles/components/roundTrips/roundTripsData';
import { excelCreateData } from 'utils/pages/roundTrips';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import StopStation from '../../../../assets/img/roundTrips/stop_station.svg';
import PersonPinCircleIcon from '@mui/icons-material/PersonPinCircle';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { LoadedStatus } from './LoadedStatus';
import { PersonPopover } from 'app/components/RoundTrips/components/PersonPopover';
import { findRole } from 'app/components/RoundTrips/function';
import { userNameByRole } from 'app/components/RoundTrips/RoundTripData/functions';
import {
  Driver,
  Picker,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { RoundTripsStatus } from 'app/components/RoundTrips/RoundTripData/RoundTripStatus';

export const ExcelData = ({ rowData, theme, i, roundTripCopy, stops }) => {
  rowData?.data?.push({
    data: excelCreateData(
      {
        value: roundTripCopy[i].id,
      },
      {
        value: (
          <div>
            <span>{roundTripCopy[i].code}</span>
            <span>
              <LocalShippingIcon
                sx={{
                  width: 14,
                  color: theme.greenStatus,
                }}
              />
            </span>
          </div>
        ),
        // sort: roundTripObj.keyPoint,
      },
      {
        value: (
          <DriverRipperFields>
            <div className="name">
              {userNameByRole(Driver, roundTripCopy[i])}/
            </div>
            <PersonPopover />
          </DriverRipperFields>
        ),
      },
      {
        value: (
          <div>
            {roundTripCopy[i]?._vehicle?.numberPlate &&
              roundTripCopy[i]?._vehicle?.model &&
              roundTripCopy[i]?._vehicle?.parkNumber &&
              roundTripCopy[i]?._vehicle?.numberPlate +
                '/' +
                roundTripCopy[i]?._vehicle?.model +
                '/idf - PN: ' +
                roundTripCopy[i]?._vehicle?.parkNumber}
          </div>
        ),
      },
      {
        value: (
          <DriverRipperFields>
            <div className="name">
              {roundTripCopy[i].agents.find(agent => {
                return findRole(agent, Picker);
              })?._user.firstName +
                ' ' +
                roundTripCopy[i].agents.find(agent => {
                  return findRole(agent, Picker);
                })?._user.lastName}
            </div>
            <PersonPopover />
          </DriverRipperFields>
        ),
      },

      {
        value: (
          <StopsDetailsDescription>
            {roundTripCopy[i].countOrders &&
              roundTripCopy[i].countOrders !== 0 && (
                <div className="count-icon">
                  <span>{roundTripCopy[i].countOrders}</span>
                  <PersonPinCircleIcon
                    sx={{ width: 18, color: theme.primary }}
                  />
                </div>
              )}
            {roundTripCopy[i].countGasStations &&
              roundTripCopy[i].countGasStations !== 0 && (
                <div className="count-icon">
                  <span>+ {roundTripCopy[i].countGasStations}</span>
                  <LocalGasStationIcon
                    sx={{ width: 18, color: theme.primary }}
                  />
                </div>
              )}
            {roundTripCopy[i].countGarages &&
              roundTripCopy[i].countGarages !== 0 && (
                <div className="count-icon">
                  <div>+</div>

                  <>
                    <span className="plus-stop">
                      +{roundTripCopy[i].countGarages}
                    </span>
                    <SettingsSuggestIcon
                      sx={{ width: 18, color: theme.primary }}
                    />
                  </>
                </div>
              )}
          </StopsDetailsDescription>
        ),
      },

      {
        value: <LoadedStatus roundTrip={roundTripCopy} />,
      },
      {
        value: <div>___</div>,
      },
      {
        value: <div>400.989 €</div>,
        // sort: roundTripObj.parkingPlace,
      },
      {
        value: <div>434.489 €</div>,
        // sort: roundTripObj.parkingPlace,
      },
      {
        value: <div>30.989 €</div>,
        // sort: roundTripObj.parkingPlace,
      },

      {
        value: <div>400.989 €</div>,
      },

      {
        value: <div>3378590512</div>,
      },
      {
        value: <RoundTripsStatus status={roundTripCopy[i].status} />,
      },

      {
        value: (
          <div>
            {roundTripCopy[i]?._vehicle.parkNumber &&
              roundTripCopy[i]?._vehicle.parkNumber}
          </div>
        ),
      },
      {
        // value: roundTripObj.plugged ? (
        //   <CheckIcon sx={{ color: theme.green }} />
        // ) : (
        //   <CloseIcon sx={{ color: theme.errorMain }} />
        // ),
        value: <CheckIcon sx={{ color: theme.green }} />,
      },
    ),
    accordion: stops,
  });

  return rowData;
};
