import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { FilterState } from './types';
import { OrderFilterType } from 'app/pages/Orders/types';

export const initialState: FilterState = {
  orderFilters: {
    priority: [],
    status: [],
    deliveryType: [],
    quantity: { minQuantity: 0, maxQuantity: null },
    lines: { minProducts: 0, maxProducts: null },
    begin: null,
    end: null,
    salesmanIds: [],
    createdByIds: [],
    productIds: [],
    withDeleted: false,
  },
};

const slice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    setOrderFilters: (
      state,
      { payload: { filters } }: PayloadAction<{ filters: OrderFilterType }>,
    ) => {
      state.orderFilters = filters;
    },
  },
});

const filterReducer = slice.reducer;

export const { actions: filterActions } = slice;

export default filterReducer;
