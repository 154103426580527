type Day = {
  abbreviation: string;
  full: string;
};

export const days: Day[] = [
  { abbreviation: 'Mon', full: 'monday' },
  { abbreviation: 'Tue', full: 'tuesday' },
  { abbreviation: 'Wed', full: 'wednesday' },
  { abbreviation: 'Thu', full: 'thursday' },
  { abbreviation: 'Fri', full: 'friday' },
  { abbreviation: 'Sat', full: 'saturday' },
  { abbreviation: 'Sun', full: 'sunday' },
];
