import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const Paragraphe = styled.p`
  font-weight: 400;
  line-height: 143%;
  letter-spacing: 0.17px;
  color: ${themes?.default?.textColorPrimary};
  display: flex;
  align-items: center;

  span {
    margin-right: 5px;
  }
`;

export const Bold = styled.p`
  font-weight: 500;
`;
