import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { useTranslation } from 'react-i18next';

interface Props {
  handleEdit?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;

  operators: string[];
  handleSelectedValue: (value: any) => void;
  data: any;
}

export const Operator: React.FC<Props> = ({
  operators: headCells,
  handleSelectedValue,
  data,
}) => {
  const { t } = useTranslation();

  const defaultProps = {
    options: headCells,
    getOptionLabel: (option: string) => t(`common.filter.${option}`),
  };

  return (
    <Autocomplete
      onChange={(e, value) => {
        handleSelectedValue(value);
      }}
      inputValue={data?.operator || ''}
      style={{ flex: 1 }}
      {...defaultProps}
      autoComplete={false}
      renderInput={params => (
        <TextField
          placeholder={t('common.filter.operator')}
          style={{ width: 150 }}
          {...params}
          variant="standard"
        />
      )}
    />
  );
};
