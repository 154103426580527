import { Card, CardContent, Grid, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';

interface Props {
  selectTemplate: string | null;
  handleTemplateClick: Function;
  type: 'roundtrips' | 'orders';
}

export function BlankTemplate({
  selectTemplate,
  handleTemplateClick,
  type,
}: Props) {
  const { t } = useTranslation();

  return (
    <Card
      sx={{
        width: '170px',
        maxWidth: '170px',
        mr: '13px',
        height: '208px',
        border:
          selectTemplate === null
            ? type === 'orders'
              ? '1px solid #B682FF'
              : '1px solid rgb(135, 157, 248)'
            : '1px solid transparent',
        ':hover': {
          cursor: 'pointer',
        },
      }}
      onClick={() => handleTemplateClick(null, [])}
    >
      <CardContent
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          backgroundColor: type === 'orders' ? '#F5EEFF' : '#FAFAFA',
          height: '77px',
          margin: '0',
          padding: '0',
        }}
      >
        <AddIcon
          sx={{
            height: '46px',
            width: '46px',
            color: type === 'orders' ? '#6600F4' : '#2A59FF',
          }}
        />
      </CardContent>
      <CardContent>
        <Typography fontSize="1rem" fontWeight="400">
          {t('orders.blank')}
        </Typography>
        <Typography
          fontSize="0.857rem"
          fontWeight="400"
          sx={{
            color: 'grey',
            mt: '1px',
          }}
        >
          {type === 'orders'
            ? t('orders.subtitle.create_order_groundUp')
            : t('roundtrip.templates.blank')}
        </Typography>
      </CardContent>
    </Card>
  );
}
