import { Popover } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  FilterContainer,
  FilterSection,
} from 'app/components/FilterComponents/styles';
import CheckboxGrid from 'app/components/FilterComponents/components/CheckboxGrid';
import { FilterHeader } from 'app/components/FilterComponents/components/FilterHeader';
import { useCheckValues } from 'app/components/FilterComponents/hooks/useCheckValues';
import MultiSelect from 'app/components/FilterComponents/components/MultiSelect';
import { useGetUsersQuery } from 'common/services/userApi';
import { useMemo, useState, useEffect } from 'react';
import If from 'app/components/If';
import { useGetWarehousesQuery } from 'common/services/organizationApi';
import { handleSelectFilterChange } from 'app/helpers/helpers';
import { useDebounce } from 'common/hooks/useDebounce';
import { useQueryParams } from 'hooks/useQueryParams';
import { Chip, Tooltip } from '@mui/material';
import { RoundtripStatus } from 'app/components/RoundTrips/RoundTripData/statuses';
import { getStatusesToShow } from 'app/pages/AddRoundTrips/functions';
import ValueSlider from 'app/components/FilterComponents/components/ValueSlider';
import { FormControlLabel, Switch } from '@mui/material';
import styled from 'styled-components';

interface StopsFilterProps {
  setFilters: React.Dispatch<
    React.SetStateAction<{
      status: { value: string; label: string }[];
      warehouse: { value: string; label: string }[];
      type: { value: string; label: string }[];
      weight: { minWeight: any; maxWeight: any };
      noVehicle?: boolean | string;
      noDriver?: boolean | string;
      noRipper?: boolean | string;
      noStops?: boolean | string;
    }>
  >;
  filters: {
    status: { value: string; label: string }[];
    warehouse: { value: string; label: string }[];
    type: { value: string; label: string }[];
    weight: { minWeight: any; maxWeight: any };
    noVehicle?: boolean | string;
    noDriver?: boolean | string;
    noRipper?: boolean | string;
    noStops?: boolean | string;
  };
  filterOn: HTMLButtonElement | null;
  handleCloseFilter: () => void;
  minWeight?: any;
  maxSliderWeight?: any;
}

export const StopsFilter = ({
  filters,
  setFilters,
  filterOn,
  handleCloseFilter,
  minWeight,
  maxSliderWeight,
}: StopsFilterProps) => {
  const { t } = useTranslation();

  const roundtripStatusesToShow = [
    RoundtripStatus.Pending,
    RoundtripStatus.Planned,
    RoundtripStatus.Loaded,
    RoundtripStatus.OnWay,
    RoundtripStatus.Completed,
    RoundtripStatus.Paused,
    RoundtripStatus.OnDock,
    RoundtripStatus.Unloaded,
    RoundtripStatus.Parked,
    RoundtripStatus.Canceled,
  ];

  const statusesToShow = getStatusesToShow(roundtripStatusesToShow);

  const openFilter = Boolean(filterOn);
  const popOverId = openFilter ? 'filter' : undefined;

  const handleOptionsChange = (
    filterKey: string,
    options: { value: string; label: string }[],
  ) => {
    handleSelectFilterChange(filterKey, options, setFilters);
  };

  const [weightSliderMarks, setWeightSliderMarks] = useState([
    {
      value: minWeight == maxSliderWeight ? 0 : minWeight,
      label: minWeight == maxSliderWeight ? 0 : minWeight,
    },
    {
      value: maxSliderWeight,
      label: maxSliderWeight,
    },
  ]);

  const handleWeightSliderChange = (sliderValue: number[]) => {
    setFilters(prevState => ({
      ...prevState,
      weight: {
        minWeight: sliderValue?.[0] || minWeight || 0,
        maxWeight: sliderValue?.[1] || maxSliderWeight,
      },
    }));
  };

  const toggleNoVehicle = () => {
    setFilters(prevState => ({
      ...prevState,
      noVehicle: !prevState.noVehicle,
    }));
  };

  const toggleNoDriver = () => {
    setFilters(prevState => ({
      ...prevState,
      noDriver: !prevState.noDriver,
    }));
  };

  const toggleNoRipper = () => {
    setFilters(prevState => ({
      ...prevState,
      noRipper: !prevState.noRipper,
    }));
  };

  const toggleNoStops = () => {
    setFilters(prevState => ({
      ...prevState,
      noStops: !prevState.noStops,
    }));
  };

  const handleClearAll = (e: React.MouseEvent) => {
    setFilters({
      status: [],
      warehouse: [],
      type: [],
      weight: {
        minWeight: 0,
        maxWeight: null,
      },
      noVehicle: '',
      noDriver: '',
      noRipper: '',
      noStops: '',
    });
  };

  useEffect(() => {
    setWeightSliderMarks([
      {
        value: minWeight == maxSliderWeight ? 0 : minWeight,
        label: minWeight == maxSliderWeight ? 0 : minWeight,
      },
      {
        value: maxSliderWeight,
        label: maxSliderWeight,
      },
    ]);
  }, [filters, minWeight, maxSliderWeight]);

  return (
    <Popover
      id={popOverId}
      open={openFilter}
      anchorEl={filterOn}
      onClose={handleCloseFilter}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <FilterContainer>
        <FilterHeader onClearAll={handleClearAll} />
        <FilterSection>
          <MultiSelect
            filterName={t('roundtrip_status')}
            selectedOptions={filters.status}
            handleFilterChange={options => {
              handleOptionsChange('status', options);
            }}
            filterItems={statusesToShow?.map(status => {
              return {
                value: status?.status,
                label: t(`orders.status.${status.status}`),
                disabled: false,
                color: status?.color,
              };
            })}
          />
        </FilterSection>
        <FilterSection>
          <ValueSlider
            sliderValue={[
              filters?.weight?.minWeight,
              filters?.weight?.maxWeight,
            ]}
            filterName={t('roundtrips.others.weight')}
            marks={weightSliderMarks}
            handleFilterChange={handleWeightSliderChange}
            minQuantity={minWeight}
            maxQuantity={maxSliderWeight}
          />
        </FilterSection>
        <FilterSection>
          <CustomControlLabel
            isActive={!!filters?.noVehicle}
            control={
              <Switch
                size="small"
                onChange={toggleNoVehicle}
                checked={!!filters?.noVehicle}
                value={filters?.noVehicle}
              />
            }
            label={t(`roundtrips.noVehicle`)}
            sx={{ marginLeft: 0, marginTop: '8px' }}
          />
        </FilterSection>
        <FilterSection>
          <CustomControlLabel
            isActive={!!filters?.noDriver}
            control={
              <Switch
                size="small"
                onChange={toggleNoDriver}
                checked={!!filters?.noDriver}
                value={filters?.noDriver}
              />
            }
            label={t(`roundtrips.noDriver`)}
            sx={{ marginLeft: 0, marginTop: '8px' }}
          />
        </FilterSection>
        <FilterSection>
          <CustomControlLabel
            isActive={!!filters?.noRipper}
            control={
              <Switch
                size="small"
                onChange={toggleNoRipper}
                checked={!!filters?.noRipper}
                value={filters?.noRipper}
              />
            }
            label={t(`roundtrips.noRipper`)}
            sx={{ marginLeft: 0, marginTop: '8px' }}
          />
        </FilterSection>
        <FilterSection>
          <CustomControlLabel
            isActive={!!filters?.noStops}
            control={
              <Switch
                size="small"
                onChange={toggleNoStops}
                checked={!!filters?.noStops}
                value={filters?.noStops}
              />
            }
            label={t(`roundtrips.noStops`)}
            sx={{ marginLeft: 0, marginTop: '8px' }}
          />
        </FilterSection>
      </FilterContainer>
    </Popover>
  );
};

interface CustomControlLabelProps {
  isActive?: boolean;
}
const CustomControlLabel = styled(FormControlLabel)<CustomControlLabelProps>`
  color: ${props => (props.isActive ? props.theme.primaryActiveColor : '')};
`;
