import { intersection, size } from 'lodash';

export function checkUserAcl(
  user: any,
  roles: string[],
  organizationId?: string,
): boolean {
  if (!user) {
    return false;
  }

  const acl =
    user?.currentAgent?._role?.acl ||
    user.agents.find(a => a._organization === organizationId)?._role.ac;

  return (acl && checkAcl(acl, roles)) || user?.isSuperAdmin;
}

export function checkAcl(acl: string[], roles: string[]): boolean {
  if (size(intersection(acl, roles)) > 0 || acl?.indexOf('*') > -1) {
    return true;
  }

  return false;
}

export function checkUserRole(user: any, keys: string[]): boolean {
  if (!user) {
    return false;
  }

  const lowercaseKeys = keys.map(k => k.toLowerCase());

  // @ts-ignore
  return lowercaseKeys.includes(user?.currentAgent?._role?.key?.toLowerCase());
}

export function isLogisticOrAdmin(user: any) {
  return checkUserAcl(user, ['logistic', 'admin']);
}
