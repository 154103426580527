import { selectAuthUser } from 'app/slices/auth/selectors';
import { checkUserRole } from 'common/helpers/acl';
import { useSelector } from 'react-redux';

export function useRoleAcl() {
  const authUser = useSelector(selectAuthUser);
  return function (role: string | string[]) {
    const roles = ([] as string[]).concat(role);
    return checkUserRole(authUser, roles);
  };
}

export function useIsLogistic() {
  const acl = useRoleAcl();
  return acl(['logistic', 'admin']);
}
