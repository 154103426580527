import { useSelector } from 'react-redux';
import { selectAuthUser } from 'common/store/auth/selectors';
import { checkUserAcl } from 'common/helpers/acl';
import { selectOrganization } from 'common/store/organization/selectors';

export default function IfUserAcl({
  user,
  children,
  acl,
}: {
  user?: any;
  children: any;
  acl: any;
}) {
  const authUser = useSelector(selectAuthUser);
  const organization = useSelector(selectOrganization);
  const checkUser = user || authUser;

  if (checkUserAcl(checkUser, acl, organization?._id)) {
    return children;
  }

  return null;
}
