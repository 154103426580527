import { Grid, Typography } from '@mui/material';

export function BottomFooterMap() {
  return (
    <Grid
      sx={{
        width: '100%',
        height: '40px',
        backgroundColor: '#fff',
        position: 'absolute',
        bottom: 0,
        zIndex: 9999,
      }}
    >
      <Grid container alignItems="center" justifyContent="center">
        <Grid
          sx={{
            padding: '8px 16px',
            gap: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className="blueDot"
            style={{
              height: '8px',
              width: '8px',
              backgroundColor: '#00BFA5',
              borderRadius: '100px',
              marginRight: '7px',
            }}
          />
          <Typography>{'€/kg > 6'}</Typography>
        </Grid>
        <Grid
          sx={{
            padding: '8px 16px',
            gap: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className="orangeDot"
            style={{
              height: '8px',
              width: '8px',
              backgroundColor: '#FF9800',
              borderRadius: '100px',
              marginRight: '7px',
            }}
          />
          <Typography>{' 3<= €/kg =>6  '}</Typography>
        </Grid>
        <Grid
          sx={{
            padding: '8px 16px',
            gap: '8px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <div
            className="redDot"
            style={{
              height: '8px',
              width: '8px',
              backgroundColor: '#F44336',
              borderRadius: '100px',
              marginRight: '7px',
            }}
          />
          <Typography>{'€/kg < 3'}</Typography>
        </Grid>
      </Grid>
    </Grid>
  );
}
