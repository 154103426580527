import { RoundtripStatus } from 'app/components/RoundTrips/RoundTripData/statuses';
import { themes } from 'styles/theme/themes';

export enum ClusterStatus {
  Active = 'active',
  InActive = 'inactive',
}

export const status = [
  { status: 'Full' },
  { status: 'First' },
  { status: 'None' },
];

export const RoundtripStatuses = [
  {
    status: RoundtripStatus.Pending,
    color: themes?.default?.warningMain,
  },
  {
    status: RoundtripStatus.Planned,
    color: themes?.default?.greenStatus,
  },
  {
    status: RoundtripStatus.Prepared,
    color: themes?.default?.purple,
  },
  {
    status: RoundtripStatus.Loading,
    color: themes?.default?.primaryActiveColor,
  },
  {
    status: RoundtripStatus.Loaded,
    color: themes?.default?.primaryActiveColor,
  },
  {
    status: RoundtripStatus.OnWay,
    color: themes?.default?.darkOrchid,
    key: 'onway',
  },
  {
    status: RoundtripStatus.Completed,
    color: themes?.default?.lasPalmas,
  },
  {
    status: RoundtripStatus.OnDock,
    color: themes?.default?.greenMain,
    key: 'ondock',
  },
  {
    status: RoundtripStatus.Unloading,
    color: themes?.default?.summerSky,
  },
  {
    status: RoundtripStatus.Unloaded,
    color: themes?.default?.summerSky,
  },
  {
    status: RoundtripStatus.Parked,
    color: themes?.default?.textColorPrimary,
  },
  {
    status: RoundtripStatus.Paused,
    color: themes?.default?.echoBlue,
  },
  {
    status: RoundtripStatus.Canceled,
    color: themes?.default?.redStatus,
  },
];

export const RoundtripStatusesActiveDate = [
  RoundtripStatus.Pending,
  RoundtripStatus.Planned,
  // RoundtripStatus.Loading,
  RoundtripStatus.Loaded,
  RoundtripStatus.OnWay,
  RoundtripStatus.Completed,
  RoundtripStatus.OnDock,
  // RoundtripStatus.Unloading,
  RoundtripStatus.Unloaded,
  RoundtripStatus.Parked,
  RoundtripStatus.Paused,
  RoundtripStatus.Canceled,
];

export const RoundtripStatusesInActiveDate = [
  RoundtripStatus.Pending,
  RoundtripStatus.Planned,
  RoundtripStatus.Prepared,
  // RoundtripStatus.Loading,
  RoundtripStatus.Loaded,
  RoundtripStatus.Canceled,
];
