import { Stack } from '@mui/material';
export const ComponentsStack = ({ children }) => {
  return (
    <Stack
      direction="row"
      justifyContent="flex-end"
      justifyItems="center"
      alignItems="center"
      gap="10px"
    >
      {children}
    </Stack>
  );
};
