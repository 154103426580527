import React, { useEffect } from 'react';

export const useResetPaginaton = (
  setPage: React.Dispatch<React.SetStateAction<number>>,
  dependencyAarray: any[],
) => {
  useEffect(() => {
    setPage(1);
  }, [setPage, ...dependencyAarray]);
};
