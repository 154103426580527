import { themes } from 'styles/theme/themes';

export enum OrderStatus {
  Initiated = 'initiated',
  InitiatedCustomer = 'initiated-customer',
  ToBePrepared = 'confirmed',
  Prepared = 'prepared',
  Loaded = 'loaded',
  ReadyForDelivery = 'ready-for-delivery',
  Pending = 'pending',
  Postponed = 'postponed',
  Canceled = 'canceled',
  Blocked = 'blocked',
  OnTheRoad = 'on-the-road',
  Delivered = 'delivered',
  Validated = 'validated',
  PendingCustomer = 'pending-customer', // pending update from client
  Deleted = 'deleted',
  Unloaded = 'unloaded',
}

export const OrderStatuses = [
  {
    status: OrderStatus.Initiated,
    classNameCircle: 'orange_circle',
    background: themes?.default?.whisper,
    color: themes?.default?.textPrimary,
    dotColor: themes?.default?.grey400,
  },
  {
    status: OrderStatus.InitiatedCustomer,
    classNameCircle: 'orange_circle',
    background: themes?.default?.whisper,
    color: themes?.default?.textPrimary,
    dotColor: themes?.default?.grey400,
  },
  {
    status: OrderStatus.Pending,
    classNameCircle: 'orange_circle',
    background: themes?.default?.bgPending,
    color: themes?.default?.textPending,
  },
  {
    status: OrderStatus.PendingCustomer,
    classNameCircle: 'orange_circle',
    background: themes?.default?.bgPendingCustomer,
    color: themes?.default?.textPendingCustomer,
  },
  {
    status: OrderStatus.Validated,
    classNameCircle: 'green_circle',
    background: themes?.default?.blue50,
    color: themes?.default?.blue900,
    dotColor: themes?.default?.blueA400,
  },
  {
    status: OrderStatus.ToBePrepared,
    label: 'to-be-prepared', //This an exception label
    classNameCircle: 'yellow_circle',
    background: themes?.default?.teal50,
    color: themes?.default?.teal800,
    dotColor: themes?.default?.teal300,
  },
  {
    status: OrderStatus.Prepared,
    classNameCircle: 'purple_circle',
    background: themes?.default?.purple50,
    color: themes?.default?.purple,
    dotColor: themes?.default?.deepPurple300,
  },
  {
    status: OrderStatus.Loaded,
    classNameCircle: 'blue_circle',
    background: themes?.default?.lightblue900,
    color: themes?.default?.lightblue50,
    dotColor: themes?.default?.lightBlue300,
  },
  {
    status: OrderStatus.ReadyForDelivery,
    classNameCircle: 'indigo_circle',
    background: themes?.default?.lightGreen55,
    color: themes?.default?.lightGreen600,
    dotColor: themes?.default?.lightGreen400,
  },
  {
    status: OrderStatus.OnTheRoad,
    classNameCircle: 'orange_2_circle',
    background: themes?.default?.orange50,
    color: themes?.default?.orange600,
    dotColor: themes?.default?.orangeA200,
  },
  {
    status: OrderStatus.Delivered,
    classNameCircle: 'green_circle',
    background: themes?.default?.bgDelivered,
    color: themes?.default?.textDelivered,
    dotColor: themes?.default?.green800,
  },
  {
    status: OrderStatus.Canceled,
    classNameCircle: 'red_circle',
    background: themes?.default?.errorBackground,
    color: themes?.default?.errorMain,
    dotColor: themes?.default?.errorMain,
  },
  {
    status: OrderStatus.Unloaded,
    classNameCircle: 'grey_circle',
    background: '',
    color: themes?.default?.tapa,
  },
  {
    status: OrderStatus.Blocked,
    classNameCircle: 'brown_circle',
    background: themes?.default?.linen,
    color: themes?.default?.brown,
    dotColor: themes?.default?.brown,
  },
  {
    status: OrderStatus.Postponed,
    classNameCircle: 'grey_circle',
    background: '',
    color: themes?.default?.grey2,
  },
  {
    status: OrderStatus.Deleted,
    classNameCircle: 'brown_circle',
    background: '',
    color: themes?.default?.brown,
  },
];
