export interface Params {
  [key: string]: any;
}

export function useQueryParams(params: Params) {
  const searchParams = new URLSearchParams();
  // Iterate over the object and append key-value pairs to searchParams
  for (var key in params) {
    searchParams.append(key, params[key]);
  }
  const queryString = searchParams.toString();
  return `?${queryString}`;
}
