import { REOPTIMIZE } from 'app/components/TableComponent/constants';
import React from 'react';

export const useAccordionValues = (
  rows,
  setExpanded,
  setAccordionStatus,
  type,
  notAddRoundtrip,
) => {
  React.useEffect(() => {
    if (type === REOPTIMIZE || !rows) return;
    let groups = {};
    for (let i = 0; i < rows.length; i++) {
      groups[rows[i].key] = false;
    }
    setExpanded?.(groups);
  }, []);
  // React.useEffect(() => {
  //     if (type === REOPTIMIZE || !rows) return;
  //     let array: { key: string; value: boolean[] }[] = [];

  //     for (let i = 0; i < rows.length; i++) {
  //       array.push({ key: rows[i].key, value: [] });
  //     }
  //     for (let j = 0; j < rows.length; j++) {
  //       for (let i = 0; rows[j].data && i < rows[j].data.length; i++) {
  //         if (array[j]) array[j].value.push(false);
  //       }
  //     }
  //     setAccordionStatus?.(array);

  // }, [rows]);
};
