import { TextField, TextFieldProps } from '@mui/material';
import React from 'react';
import { Controller, RegisterOptions } from 'react-hook-form';
export type Props = TextFieldProps & {
  rules?: Omit<
    RegisterOptions<any>,
    'valueAsNumber' | 'valueAsDate' | 'setValueAs' | 'disabled'
  >;
  control: any;
};
export const FieldComponent: React.FC<Props> = ({
  control,
  rules,
  style,
  name,
  ref,
  ...props
}) => {
  return (
    <div style={style}>
      <Controller
        name={name || ''}
        control={control}
        rules={rules}
        render={({ field }) => (
          <TextField
            {...field}
            {...props}
            style={{ width: '100%', marginBottom: '23px !important' }}
          />
        )}
      />
    </div>
  );
};
