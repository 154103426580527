import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CalendarTodayOutlined from '@mui/icons-material/CalendarTodayOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

function CancelOrderDialog({
  open,
  onClose,
  onCancelOrder,
  orderCode,
  date,
  branch,
}) {
  const { t, i18n } = useTranslation();

  const handleClose = () => {
    onClose(true);
  };

  function formatDateToCustomFormat(dateString) {
    const options = {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    } as Intl.DateTimeFormatOptions;
    const date = new Date(dateString);
    const locale = i18n.language;
    return date.toLocaleDateString(locale, options);
  }

  const formattedDate = formatDateToCustomFormat(date);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      sx={{ width: '100%' }}
      aria-labelledby="customized-dialog-title"
    >
      <DialogTitle sx={{ m: 0, p: 2 }}>{t('orders.cancel_order')}</DialogTitle>
      <IconButton
        aria-label="close"
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: theme => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent sx={{ padding: '0 18px' }}>
        <DialogContentText
          sx={{
            bgcolor: 'rgba(211, 47, 47, 0.04)',
            p: 1,
            borderColor: 'rgba(211, 47, 47, 1)',
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '4px',
            WebkitBorderRadius: '4px', // Safari and Chrome
            MozBorderRadius: '4px', // Firefox
            msBorderRadius: '4px', // Internet Explorer
          }}
        >
          <OrderCode>
            <span>
              {t('cancel')} #{orderCode}:
            </span>
          </OrderCode>
          <TextContainer>
            <CalendarTodayOutlined
              sx={{ marginRight: '5px' }}
              fontSize="small"
            />
            <span>{formattedDate}</span>
            <Divider>|</Divider>
            <MapsHomeWorkOutlinedIcon
              sx={{ marginRight: '5px' }}
              fontSize="small"
            />
            <span>{branch?.location?.name}</span>
          </TextContainer>
        </DialogContentText>
        <br />
        <br />
        <Typography
          style={{
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Roboto',
            fontWeight: 500,
            fontSize: '1rem',
            lineHeight: '21.98px',
            letterSpacing: '0.1px',
          }}
        >
          {t('orders.cancel_confirmation_message')}
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            color: 'rgba(0, 0, 0, 0.87)',
            fontFamily: 'Roboto',
            fontWeight: 400,
            fontSize: '0.929',
            lineHeight: '17.55px',
            letterSpacing: '0.17px',
          }}
        >
          {t('orders.cancel_warning_message')}
        </Typography>
        <br />
      </DialogContent>
      <DialogActions style={{ justifyContent: 'center', marginBottom: '15px' }}>
        <Button
          onClick={onClose}
          color="primary"
          sx={{ color: 'rgba(0, 0, 0, 0.6)' }}
        >
          {t('orders.button.no_go_back')}
        </Button>
        <Button
          onClick={onCancelOrder}
          color="error"
          variant="contained"
          sx={{
            borderRadius: '42px',
            mr: '12px',
          }}
        >
          {t('orders.button.yes_cancel_order')}
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default CancelOrderDialog;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  font-family: Roboto, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 0.17px;
  text-align: left;
  color: rgba(129, 145, 152, 1);
`;

const Divider = styled.div`
  margin: 0 8px;
`;

const OrderCode = styled.div`
  color: rgba(0, 0, 0, 0.87);
  font-family: 'Roboto';
  font-size: 1rem;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.1px;
  text-align: left;
`;
