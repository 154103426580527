import { CrosshairSimple } from '@phosphor-icons/react';
import { themes } from 'styles/theme/themes';

interface AddressIconProps {
  onClick?: (e?: any) => void;
}
export const AddressIcon = ({ onClick }: AddressIconProps) => {
  return (
    <CrosshairSimple
      size={themes?.default?.iconSize}
      color={themes?.default?.primaryActiveColor}
      style={{
        marginRight: 8,
        cursor: 'pointer',
      }}
      onClick={onClick}
    />
  );
};
