import { useEffect } from 'react';

export function useSetOptimise(selected, setOptimize) {
  useEffect(() => {
    let keys = Object.keys(selected);
    for (let i = 0; i < keys?.length; i++) {
      if (selected[keys[i]]?.length) return setOptimize(true);
    }
    setOptimize(false);
  }, [selected]);
}
