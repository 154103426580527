import { TableCell } from '@mui/material';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';

interface Props {
  sx: any;
}

export const TableCellComponent: React.FC<Props> = ({ sx }) => {
  const theme = useSelector(selectTheme);

  return (
    <TableCell
      align="left"
      style={{
        flex: 1,
        minWidth: 128,
        maxWidth: 128,
        overflow: 'hidden',
        borderRight: `0.063rem solid ${theme.primaryLightO}`,
      }}
      sx={sx}
    >
      <span style={{ fontWeight: 500 }}></span>
    </TableCell>
  );
};
