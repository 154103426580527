interface IfProps {
  condition: boolean;
  otherwise?: React.ReactNode | JSX.Element | null | undefined;
  children: React.ReactNode | JSX.Element;
}
export default function If({
  condition,
  otherwise = null,
  children,
}: IfProps): any {
  return condition ? children : otherwise;
}

export { If };
