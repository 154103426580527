import * as React from 'react';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import { IconButton, TextField } from '@mui/material';

import styled from 'styled-components';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import PersonIcon from '@mui/icons-material/Person';
import InteractionPerson from '../../../../assets/img/roundTrips/interaction-person.svg';
import { SearchOutlined } from '@mui/icons-material';

export const PersonPopover = props => {
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null,
  );

  const [search, setSearch] = React.useState<string>('');
  const theme = useSelector(selectTheme);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = event => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div className="popover-wrapper">
      <IconButton onClick={handleClick} sx={{ height: 34, width: 34 }}>
        <PersonIcon
          sx={{
            position: 'absolute',
            right: '10px',
            width: '14px',
            height: '14px',
            color: open ? theme.primary : theme.textSecondary,
          }}
        />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Typography minWidth={123}>
          <PopoverWrapper>
            <TextFieldSize>
              <TextField
                size="small"
                margin="normal"
                id="standard-bare"
                style={{ height: 34, margin: 'unset' }}
                onChange={e => {
                  setSearch(e.target.value);
                }}
                value={search}
                InputProps={{
                  startAdornment: (
                    <IconButton onClick={() => {}}>
                      <SearchOutlined />
                    </IconButton>
                  ),
                }}
              />
            </TextFieldSize>

            <PersonField>
              <img alt={'Stepnan'} src={InteractionPerson} />
              <div>Stephan Macron</div>
            </PersonField>
            <PersonField>
              <img alt={'Emanuel'} src={InteractionPerson} />
              <div>Emanuel Sud</div>
            </PersonField>
            <PersonField>
              <img alt={'Oliver'} src={InteractionPerson} />
              <div>Oliver Giro</div>
            </PersonField>
          </PopoverWrapper>
        </Typography>
      </Popover>
    </div>
  );
};

const PopoverWrapper = styled.div(props => ({
  width: 186,
  fontSize: '0.8125rem',
  maxHeight: 220,
}));

const TextFieldSize = styled.div(props => ({
  padding: '8px 8px 0px 8px',
  '& .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary': {
    height: '100%',
  },
  '& button': {
    left: -10,
    height: 34,
    width: 34,
  },
}));

const PersonField = styled.div(props => ({
  display: 'flex',
  columnGap: 8,
  height: 36,
  alignItems: 'center',
  paddingLeft: 10,
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: props.theme.grey,
  },
}));
