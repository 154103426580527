import {
  Box,
  Paper,
  SxProps,
  Table,
  TableBody,
  TableContainer,
  Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useState } from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from './styles/accordion';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { SectionView } from './styles';
import { EnhancedTableHead } from './helpers';
import { TrackFunction } from './helpers/track';
import { RegularTableFunction } from './helpers/regular';
import { GridFunction } from './helpers/grid';
import { Theme } from '@mui/system';
import { ReOptimizeFunction } from './helpers/reoptimize';
import { Order } from 'app/components/TableComponent/helpers/types';
import { handleSelectAllClick } from 'app/components/TableComponent/helpers';
import {
  descendingComparator,
  getAccordionGroupInex,
  handleClickAccordionEl,
  numberOfEl,
  sortDirection,
  stableSort,
} from 'app/components/TableComponent/helpers/functions';
import styled from 'styled-components';
import {
  HeadCell,
  excelHeadCellsConst,
} from '../../../../pages/RoundTrips/data/excelHeadCellsConst';
import { handleAccordionToggle } from 'app/components/RoundTrips/function';
import { useAccordionValues } from './helpers/useAccordionValues';
import {
  EXCEL,
  GRID,
  REOPTIMIZE,
  TRACK,
} from 'app/components/TableComponent/constants';
import { ExcelFunction } from 'app/components/TableComponent/helpers/excel';
import { TypesWrapper } from 'app/components/TableComponent/styles';
import { handleAccordionToggleHover } from '../RightBar/function';
import { handleChange } from './helpers/functions';
import { themes } from 'styles/theme/themes';
import { useAccordionDetailsToggle } from 'hooks/RoundTrips/useAccordionDetailsToggle';
import { handleRequestSort } from 'functions/hnadleRequestSort';
import NoResults from 'app/components/NoData/NoResults';
import If from 'app/components/If';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';

interface Props {
  EnhancedTableToolbar?: any;
  isLoading: boolean;
  rows: any;
  page: number;
  setPage: (e) => void;
  rowsPerPage: number;
  setRowsPerPage: (e) => void;
  headCells: any;
  setExpanded?: (e) => void;
  setAccordionStatus?: (e) => void;
  expanded?: any;
  accordionStatus?: { key: string; value: boolean[] }[];
  type?: string;
  selected: any;
  setSelected: any;
  stopsAccordion?: any;
  setStopsAccordion?: any;
  sx?: SxProps<Theme> | undefined;
  selectedRoundTrip?: number;
  setSelectedRoundTrip?: React.Dispatch<React.SetStateAction<number>>;
  selectedResult?: number;
  setSelectedResult?: React.Dispatch<React.SetStateAction<number>>;
  tableHeight?: number | string;
  viewCompleted?: boolean;
  rowsDatas?: any;
  totalPages: number;
  setOrderBy?: any;
  orderBy?: any;
  order?: any;
  setOrder?: any;
  totalDocs: number;
  hidePagination?: boolean;
  setSort: Dispatch<SetStateAction<string>>;
  setDirection: Dispatch<SetStateAction<string>>;
  sort: string;
  direction: string;
  setDropableData: any;
  isDragged: any;
  handleClickActivate: any;
  triggerRoundtripDetails?: any;
  roundtripLoading?: any;
  setRoundtripLoading?: any;
  displayTruckMap?: Function;
  mapCoordinates?: any;
  search?: string;
  userContext?: AgentCategories;
}

export const TableComponent: React.FC<Props> = ({
  direction,
  setSort,
  sort,
  EnhancedTableToolbar,
  rowsDatas,
  isLoading,
  rows,
  page,
  setPage,
  rowsPerPage,
  setRowsPerPage,
  headCells,
  viewCompleted,
  setExpanded,
  setAccordionStatus,
  expanded,
  accordionStatus,
  selected,
  setSelected,
  type,
  stopsAccordion,
  setStopsAccordion,
  sx,
  selectedRoundTrip,
  setSelectedRoundTrip,
  selectedResult,
  setSelectedResult,
  tableHeight,
  totalPages,
  setOrderBy,
  orderBy,
  order,
  setOrder,
  totalDocs,
  hidePagination,
  setDirection,
  setDropableData,
  isDragged,
  handleClickActivate,
  triggerRoundtripDetails,
  roundtripLoading,
  setRoundtripLoading,
  displayTruckMap,
  mapCoordinates,
  search,
  userContext,
  ...props
}) => {
  //hooks
  useAccordionValues(rows, setExpanded, setAccordionStatus, type, false);
  const theme = useSelector(selectTheme);

  //state & variables

  const { handleAccordionToggleWrapper, roundtripLoading: loading } =
    useAccordionDetailsToggle(rows, triggerRoundtripDetails, roundtripLoading);

  const dense = true;
  let isSelected;
  let tablesData: any[] = [];
  const [selectedAccordionGroup, setSelectedAccordionGroup] = useState(
    rows[0]?.key,
  );

  //function & custom hooks

  const handleAccordionToggleHoverLeave = (group: number, index: number) => {
    let accordionStatusCopy;
    if (Array.isArray(accordionStatus)) {
      accordionStatusCopy = [...accordionStatus];
    }
    accordionStatusCopy[group].value[index] = false;
    setAccordionStatus?.(accordionStatusCopy);
  };
  const selectedAccordionGroupIndex: number = getAccordionGroupInex(
    rows,
    selectedAccordionGroup,
  );

  //useEffects

  React.useEffect(() => {
    if (setStopsAccordion) setStopsAccordion(false);
  }, [type]);
  React.useEffect(() => {
    if (!selectedAccordionGroup) {
      setSelectedAccordionGroup(rows[selectedAccordionGroupIndex]?.key);
    }
  }, [rows]);

  console.log(selectedAccordionGroup);

  // logic

  if (
    type === GRID ||
    type === TRACK ||
    type === EXCEL ||
    type === REOPTIMIZE
  ) {
    isSelected = (group, name: string) => {
      return selected[group] && selected[group].indexOf(name) !== -1;
    };
  }
  if (type === GRID) {
    tablesData = [];
    tablesData = GridFunction({
      handleClickActivate,
      accordionStatus,
      handleAccordionToggle: handleAccordionToggleWrapper,
      handleAccordionToggleHover,
      handleClickAccordionEl,
      headCells,
      isSelected,
      rows,
      selected,
      setSelected,
      theme,
      isDragged,
      handleAccordionToggleHoverLeave,
      setDropableData,
      setAccordionStatus,
      isLoading,
      roundtripLoading: loading,
      displayTruckMap,
      mapCoordinates,
      userContext,
    });
  } else if (type === EXCEL) {
    tablesData = [];
    tablesData = ExcelFunction({
      rows,
      headCells,
      stopsAccordion,
      setStopsAccordion,
      theme,
      selectedAccordionGroupIndex,
      selected,
      selectedAccordionGroup,
    });
  } else if (type === REOPTIMIZE) {
    tablesData = [];
    tablesData = ReOptimizeFunction({
      rows,
      headCells,
      stopsAccordion,
      setStopsAccordion,
      theme,
      selectedRoundTrip,
      viewCompleted,
    });
  } else if (type === TRACK) {
    tablesData = [];
    tablesData = TrackFunction({
      accordionStatus,
      handleAccordionToggle,
      handleClickAccordionEl,
      headCells,
      isSelected,
      rows,
      selected,
      setSelected,
      theme,
    });
  }
  return (
    <Box sx={{ width: '100%', ...sx }}>
      <Paper
        sx={{
          width: '100%',
          mb: 2,
          boxShadow: 'none',
          backgroundColor: theme.roundtripsGrayBackGround,
        }}
      >
        {EnhancedTableToolbar && EnhancedTableToolbar}
        {rows.length ? (
          <TableContainer sx={{ height: tableHeight || 'unset' }}>
            <SvgStyle>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={dense ? 'small' : 'medium'}
              >
                <EnhancedTableHead
                  // createSortHandler={createSortHandler}
                  setSort={setSort}
                  direction={direction}
                  setDirection={setDirection}
                  sortDirection={sortDirection}
                  selectedResult={selectedResult}
                  setSelectedResult={setSelectedResult}
                  selectedRoundTrip={selectedRoundTrip}
                  type={type}
                  numSelected={selected.length}
                  order={direction ?? ''}
                  orderBy={sort ?? ''}
                  setSelected={setSelected}
                  group={selectedAccordionGroup}
                  isSelected={isSelected}
                  onSelectColumn={handleClickAccordionEl}
                  onSelectAllClick={e => {
                    handleSelectAllClick(e, type, rows, selected, setSelected);
                  }}
                  rowCount={
                    type === GRID || type === TRACK || type === EXCEL
                      ? numberOfEl(rows)
                      : rows.length
                  }
                  isLoading={isLoading}
                  headCells={headCells}
                  selected={selected}
                  rows={rows}
                  selectedRowIndex={selectedAccordionGroupIndex}
                  onRequestSort={(event, property) => {
                    handleRequestSort(
                      property,
                      direction,
                      sort,
                      setDirection,
                      setSort,
                    );
                  }}
                />
                <TableBody>
                  {type === TRACK &&
                    tablesData?.map((tableData, index) => {
                      return (
                        <Accordion
                          key={rows[index].key}
                          expanded={!!expanded[rows[index].key]}
                          onChange={handleChange(
                            rows[index].key,
                            expanded,
                            setExpanded,
                            accordionStatus,
                            setAccordionStatus,
                            'multiple',
                          )}
                        >
                          <div
                            style={{
                              height: 32,
                              background: theme.primaryActiveO,
                              borderBottom: `0.063rem solid ${theme.borderGrey}`,
                            }}
                          >
                            <AccordionSummary
                              aria-controls="panel1d-content"
                              id="panel1d-header"
                              expandIcon={null}
                              sx={{
                                width: 'fit-content',
                                height: '100%',
                                minHeight: 'unset',
                                position: 'sticky',
                                left: 0,
                              }}
                            >
                              <Typography>
                                <SectionView
                                  expanded={!!expanded[rows[index].key]}
                                >
                                  {!!expanded[rows[index].key] ? (
                                    <ArrowDropUpIcon
                                      sx={{
                                        color:
                                          themes?.default?.accordionWhiteBg,
                                      }}
                                    />
                                  ) : (
                                    <ArrowDropDownIcon
                                      sx={{
                                        color:
                                          themes?.default?.accordionWhiteBg,
                                      }}
                                    />
                                  )}
                                  <div className="stats-dropdown-text">
                                    {rows[index].key}
                                  </div>
                                </SectionView>
                              </Typography>
                            </AccordionSummary>
                          </div>
                          <AccordionDetails>{tableData}</AccordionDetails>
                        </Accordion>
                      );
                    })}
                  {(type === EXCEL || type === REOPTIMIZE) && tablesData[0]}
                  {type === GRID &&
                    tablesData?.map((tableData, index) => {
                      return (
                        <React.Fragment key={index}>{tableData}</React.Fragment>
                      );
                    })}
                </TableBody>
              </Table>
            </SvgStyle>
          </TableContainer>
        ) : (
          <If condition={!!(search && search !== '')}>
            <EmptyCenter>
              <NoResults />
            </EmptyCenter>
          </If>
        )}
        {type === EXCEL && (
          <TypesWrapper>
            {rows.map(row => {
              return (
                <div
                  key={row.key}
                  onClick={() => {
                    setSelectedAccordionGroup(row.key);
                  }}
                  className={`accordion-el${
                    selectedAccordionGroup === row.key ? ' selected' : ''
                  }`}
                >
                  {row.key}
                </div>
              );
            })}
          </TypesWrapper>
        )}
        {/* <TablePaginationWrapper>
          <TablePagination
            rowsPerPageOptions={[10, 20, 50, 100]}
            component="div"
            count={totalDocs ?? 0}
            rowsPerPage={rowsPerPage}
            page={page - 1}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            ActionsComponent={() => {
              return <></>;
            }}
          />
          <Pagination
            color="primary"
            onChange={(e, val) => {
              setPage(val);
            }}
            page={page}
            count={totalPages}
            shape="rounded"
          />
        </TablePaginationWrapper> */}
      </Paper>
    </Box>
  );
};
const SvgStyle = styled.div`
  svg {
    font-size: 1.5rem;
  }
  margin-left: 17px;
`;
const EmptyCenter = styled.div`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
`;
