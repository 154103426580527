import { TableCell } from '@mui/material';
import { TimeLine } from 'app/components/TableComponent/helpers';

export function TimeLineComponent() {
  return (
    <TableCell
      align="left"
      style={{
        flex: 1,
        overflow: 'hidden',
      }}
    >
      <TimeLine>
        <div className="time">7:00</div>
        <div className="time">8:00</div>
        <div className="time">9:00</div>
        <div className="time">10:00</div>
        <div className="time">11:00</div>
        <div className="time">12:00</div>
        <div className="time">13:00</div>
        <div className="time">14:00</div>
        <div className="time">15:00</div>
        <div className="time">16:00</div>
        <div className="time">17:00</div>
        <div className="time">18:00</div>
        <div className="time">19:00</div>
        <div className="time">20:00</div>
      </TimeLine>
    </TableCell>
  );
}
