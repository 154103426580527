import { Tooltip } from '@mui/material';
import { Info } from '@phosphor-icons/react';
import { Icon } from 'app/components/Icon';

interface Props {
  reason: string;
}

export const CompletedReason = ({ reason }: Props) => {
  if (!reason) {
    return <></>;
  }
  return (
    <Tooltip title={reason}>
      <div>
        <Icon icon={<Info />} />
      </div>
    </Tooltip>
  );
};
