type ContentOnly = {
  content: any;
  href?: never;
};

type HrefOnly = {
  content?: never;
  href: string;
};

export type FileData = {
  filename: string;
} & (ContentOnly | HrefOnly);

export type OpenDownloaded = {
  latest?: boolean;
};

export type Search = {
  [key: string]: string | number | boolean | string[] | number[] | boolean[];
};

// Only pass content OR href not both at the same time
export const download = function (data: FileData) {
  const pom = document.createElement('a');
  pom.setAttribute('download', data.filename || 'file');
  if (data.content) {
    pom.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(data.content || ''),
    );
  } else if (data.href) {
    pom.setAttribute('href', data.href);
  }
  pom.click();
  pom.remove();
};

/**
 * This function is to open a downloaded file, functionalities can be added for more usecases
 * @param args
 */
export const openDownloaded = ({ latest = true }: OpenDownloaded) => {
  const search: Search = {};
  if (latest) {
    search.limit = 1;
    search.orderBy = ['-startTime'];
  }
  // TODO: find a way to implement this: https://developer.mozilla.org/en-US/docs/Mozilla/Add-ons/WebExtensions/API/downloads/open
};
