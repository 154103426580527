import { Stack, Typography } from '@mui/material';
import { StatusIndicator } from 'app/components/RoundtripStatus';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { themes } from 'styles/theme/themes';

interface StopStatusesProps {
  stats: {
    stops: number;
    delivered: number;
    canceled: number;
    postponed: number;
    completed: number;
  };
}

export const StopStatuses = ({ stats }: StopStatusesProps) => {
  const { t } = useTranslation();

  const statuses = [
    {
      text: t('roundtrips.status.completed'),
      color: themes?.default?.lasPalmas,
    },
    {
      text: t('common.remaining'),
      color: themes?.default?.warningMain,
    },
  ];
  const stopsCount = useMemo(() => {
    const totalStops = stats?.stops;
    const completedStopsCount =
      (stats?.delivered || 0) +
      (stats?.canceled || 0) +
      (stats?.postponed || 0) +
      (stats?.completed || 0);

    return [completedStopsCount, totalStops - completedStopsCount];
  }, [stats]);

  return (
    <>
      <Stack
        direction="row"
        alignItems="center"
        gap="20px"
        sx={{ position: 'absolute', bottom: '4px' }}
      >
        {statuses?.map((status, index) => (
          <>
            <Stack
              direction="row"
              alignItems="center"
              gap="5px"
              lineHeight={'1.25rem'}
            >
              <StatusIndicator color={status.color} />
              <Typography fontSize="0.8rem" fontWeight="700">
                {stopsCount?.[index]}
              </Typography>
              <Typography fontSize="0.8rem">{status.text}</Typography>
            </Stack>
          </>
        ))}
      </Stack>
    </>
  );
};
