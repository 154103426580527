export function updateFileType(file: File, newType: string): File {
  const formData = new FormData();
  formData.append('file', file.slice(0, file.size, newType), file.name);

  // Create a new File using the FormData
  const updatedFile = formData.get('file') as File;

  return updatedFile;
}

export function updateFormData(file: FormData) {
  let updatedFile: any = file.get('file');
  if (updatedFile.type === 'application/vnd.ms-excel') {
    updatedFile = updateFileType(file.get('file') as any, 'text/csv');
  }
  file.set('file', updatedFile);
  return file;
}
