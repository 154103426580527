import { useEffect } from 'react';
import { Box, Grid, Pagination, Stack, Typography } from '@mui/material';
import { useLazyGetCustomerCompletedDocumentsQuery } from 'common/services/customerApi';
import { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useDebounce } from 'common/hooks/useDebounce';
import emptyState from '../../../../../assets/img/customers/no-customers.svg';
import ModalPDFViewer from 'app/components/ModalPDFViewer';
import { TableDoc } from '../../TableDoc';
import If from 'app/components/If';

export function CompletedDoc({ search, filters }) {
  const [page, setPage] = useState(1);
  const { t } = useTranslation();
  const [doc, setDoc] = useState<any>(null);
  const [docCgv, setDocCgv] = useState<any>(null);

  const [triggerGetDocuments, { data }] =
    useLazyGetCustomerCompletedDocumentsQuery();
  const debouncedSearchTerm = useDebounce(search, 300);

  const salesmanParam =
    filters?.salesman?.map(salesman => salesman.value)?.join(',') || '';

  useEffect(() => {
    triggerGetDocuments(
      `page=${page}&text=${search}&limit=20&salesman=${salesmanParam}`,
    );
  }, [debouncedSearchTerm, page, salesmanParam]);

  const handleChangeOrderPagination = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value);
  };

  return (
    <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
      <Grid item xs={12} md={12}>
        <If
          condition={data?.docs?.length > 0}
          otherwise={
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: 'calc(100vh - 140px)',
              }}
            >
              <img src={emptyState} alt="empty state" />
              <Typography>{t('checkedDoc.emptyState.lalbel')}</Typography>
            </Box>
          }
        >
          <TableDoc
            treated={true}
            data={data}
            setDoc={setDoc}
            setDocCgv={setDocCgv}
            handleVerifyDocument={() => {}}
            handleOpenRefuseModal={() => {}}
          />

          <Stack spacing={2} sx={{ marginTop: 2 }}>
            <Pagination
              color="primary"
              onChange={handleChangeOrderPagination}
              page={page}
              count={data?.totalPages || 0}
              shape="rounded"
            />
          </Stack>
        </If>
      </Grid>
      <ModalPDFViewer
        open={doc !== null}
        url={doc?.url}
        url2={doc?.url2}
        fileName={doc?.name}
        onClose={() => setDoc(null)}
      />
      <ModalPDFViewer
        open={docCgv !== null}
        url={docCgv}
        fileName={'CGV'}
        onClose={() => setDocCgv(null)}
      />
    </Grid>
  );
}

const CardContentWrapper = styled.div`
  display: flex;
`;

const DetailDocWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const DocWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const NameValidatorWrapper = styled.div`
  margin-left: 20px;
  width: 196px;
`;

const ChipWrapper = styled.div`
  margin-right: 5px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  margin-top: 10px;
`;

const TypoDateWrapper = styled.div`
  width: 156px;
`;

const ButtonDocWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const TypoWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const Container = styled.div`
  width: 150px;
  text-align: center;
  height: 35px;
`;

const TitleWrapper = styled.div`
  display: flex;
  margin-bottom: 5px;
`;

const CGVWrapper = styled.div`
  display: flex;
  align-items: center;
`;
