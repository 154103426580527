import { IconButton } from '@mui/material';
import { ArrowsIn, ArrowsOut } from '@phosphor-icons/react';
import { toolsActions } from 'app/slices/tools';
import { selectIsZoomedIn } from 'app/slices/tools/selectors';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { themes } from 'styles/theme/themes';

export const ZoomButton = () => {
  const zoomedIn = useSelector(selectIsZoomedIn);
  const dispatch = useDispatch();

  return (
    <IconButton
      onClick={() => {
        dispatch(toolsActions.setZoomIn({ status: !zoomedIn }));
      }}
      sx={{
        zIndex: '1200',
      }}
    >
      {zoomedIn ? (
        <ArrowsIn
          size={themes.default.iconSize}
          color={themes.default.iconColor}
        />
      ) : (
        <ArrowsOut
          size={themes.default.iconSize}
          color={themes.default.iconColor}
        />
      )}
    </IconButton>
  );
};
