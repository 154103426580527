import { Grid, Slider } from '@mui/material';
import RemoveIcon from '@mui/icons-material/Remove';
import AddIcon from '@mui/icons-material/Add';
import If from 'app/components/If';
import { FilterName, ToggleIcon } from '../../styles';
import { useEffect, useState, useMemo } from 'react';

interface ValueSliderProps {
  openByDefault?: boolean;
  filterName: string;
  sliderValue: number[];
  handleFilterChange: (value: any) => void;
  marks: { value: number; label: number | undefined }[];
  minQuantity?: number | null;
  maxQuantity?: number | null;
  negative?: boolean;
}

const ValueSlider = ({
  openByDefault,
  filterName,
  sliderValue,
  marks,
  handleFilterChange,
  minQuantity,
  maxQuantity,
  negative,
}: ValueSliderProps) => {
  const isOpen = !!openByDefault;
  const [open, setOpen] = useState<boolean>(isOpen || false);
  const [value, setValue] = useState<any[]>([
    sliderValue?.[0] || 0,
    sliderValue?.[1] || null,
  ]);

  const handleChangeValue = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };

  const isHighlighted = useMemo(() => {
    return (sliderValue[0] && sliderValue[0] !== 0) || sliderValue[1];
  }, [sliderValue[0], sliderValue[1]]);

  useEffect(() => {
    console.log(sliderValue);
  }, []);
  useEffect(() => {
    if (!negative) {
      if (value?.[0] || value?.[1]) {
        setValue([sliderValue?.[0] || 0, sliderValue?.[1] || null]);
      }
    } else {
      if (
        (value?.[0] === 0 || value?.[0] === minQuantity) &&
        value?.[1] === null
      ) {
        setValue([minQuantity, maxQuantity]);
      }
    }
  }, [sliderValue]);

  return (
    <>
      <div className="title_action" onClick={() => setOpen(!open)}>
        <FilterName isHighlighted={!!isHighlighted}>{filterName}</FilterName>
        {
          <ToggleIcon disableRipple>
            {open ? <RemoveIcon /> : <AddIcon />}
          </ToggleIcon>
        }
      </div>
      <If condition={open}>
        <Grid container spacing={0} sx={{ padding: '10px' }}>
          <Slider
            valueLabelDisplay="auto"
            value={value}
            disableSwap
            min={minQuantity || 0}
            max={maxQuantity || 300}
            marks={marks}
            onChange={handleChangeValue}
            onChangeCommitted={() => {
              handleFilterChange(value);
            }}
          />
        </Grid>
      </If>
    </>
  );
};

export default ValueSlider;
