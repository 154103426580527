import { roundtripActions } from 'common/store/roundtrips';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';

function useRoundtripStorage(dateBegin, dateEnd, accordionStatus, dateValue) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(roundtripActions.setDateBegin(dateBegin));
    dispatch(roundtripActions.setDateEnd(dateEnd));
    dispatch(roundtripActions.setAccordionStatus(accordionStatus));
    if (dateValue && dateValue !== null) {
      dispatch(roundtripActions.setDateValue(dateValue.format('YYYY-MM-DD')));
    }
  }, [dateBegin, dateEnd, dateValue, accordionStatus]);
}

export default useRoundtripStorage;
