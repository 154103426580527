import React from 'react';
export const useSelectedTable = (rows, accordion) => {
  const [selected, setSelected] = React.useState<any>([]);

  React.useEffect(() => {
    if (!accordion) return;
    let groups = {};
    for (let i = 0; i < rows.length; i++) {
      groups[rows[i].key] = [];
    }
    setSelected(groups);
  }, [rows]);

  return [selected, setSelected];
};
