import { Avatar, Chip } from '@mui/material';
import { themes } from 'styles/theme/themes';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { ManagedTooltip } from 'app/components/TableComponent/helpers/Components/ManagedTooltip';

interface VariationChipProps {
  value: number;
  tooltipTitle?: string;
}
export const VariationChip = ({ value, tooltipTitle }: VariationChipProps) => {
  const displayedValue = Math.round(Math.abs(value));
  const iconSx = { width: '14px', height: '14px' };

  const type = value === 0 ? 'stable' : value > 0 ? 'increasing' : 'decreasing';
  const typeOptions = {
    increasing: {
      color: themes?.default?.green3,
      backgroundColor: themes?.default?.chipGreenFont,
      icon: (
        <Avatar sx={{ backgroundColor: 'transparent' }}>
          <ArrowUpwardIcon sx={{ ...iconSx, color: themes?.default?.green3 }} />
        </Avatar>
      ),
    },
    decreasing: {
      color: themes?.default?.chipRedBg,
      backgroundColor: themes?.default?.chipRedFont,
      icon: (
        <Avatar sx={{ backgroundColor: 'transparent' }}>
          <ArrowDownwardIcon
            sx={{ ...iconSx, color: themes?.default?.chipRedBg }}
          />
        </Avatar>
      ),
    },
    stable: {
      color: themes?.default?.blue600,
      backgroundColor: themes?.default?.blue50,
      icon: <></>,
    },
  };

  const color = typeOptions[type].color;
  const backgroundColor = typeOptions[type].backgroundColor;
  const icon = typeOptions[type].icon;
  const label = `${displayedValue}%`;

  return (
    <>
      <ManagedTooltip title={tooltipTitle} disabled={!tooltipTitle}>
        <Chip
          size="small"
          avatar={icon}
          label={label}
          sx={{
            color: color,
            background: backgroundColor,
            border: `1px solid ${color}`,
          }}
        />
      </ManagedTooltip>
    </>
  );
};
