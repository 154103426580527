import { Grid, Typography } from '@mui/material';
import { StatusDot } from '../StatusDot';
import If from 'app/components/If';
import { useTranslation } from 'react-i18next';
import { RoundtripsType } from 'app/pages/RoundTrips/data/types';

export function ResourcesStatusHeader({
  countRoundtrip,
  countDrivers,
  countTrucks,
  userContext,
}) {
  const { t } = useTranslation();

  const capitalizeFirstLetter = string => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  return (
    <Grid
      container
      alignItems="center"
      gap="14px"
      sx={{
        ml: '40px',
        mb: '10px',
      }}
    >
      <Grid
        container
        alignItems="center"
        sx={{
          width: '245px',
        }}
      >
        <If
          condition={countRoundtrip !== 0}
          otherwise={
            <>
              <StatusDot color="#E53935" />
              <Typography>
                {t('info.roundtrips')} <b>- {t('tools.unavailable')}</b>
              </Typography>
            </>
          }
        >
          <StatusDot color="#43A047" />
          <Typography>
            {t('info.roundtrips')}{' '}
            <b>
              - {countRoundtrip} {t('tools.available')}
            </b>
          </Typography>
        </If>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          width: '245px',
        }}
      >
        <If
          condition={countDrivers !== 0}
          otherwise={
            <>
              <StatusDot color="#E53935" />
              <Typography>
                {userContext === RoundtripsType.Sales
                  ? capitalizeFirstLetter(RoundtripsType.Sales)
                  : capitalizeFirstLetter(RoundtripsType.Drivers)}{' '}
                <b>- {t('tools.unavailable')}</b>
              </Typography>
            </>
          }
        >
          <StatusDot color="#43A047" />
          <Typography>
            {userContext === RoundtripsType.Sales
              ? t('sales')
              : t('nav.mainNav.drivers')}{' '}
            <b>
              - {countDrivers} {t('tools.available')}
            </b>
          </Typography>
        </If>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          width: '245px',
        }}
      >
        <If
          condition={countTrucks !== 0}
          otherwise={
            <>
              <StatusDot color="#E53935" />
              <Typography>
                {t('trucks.title')} <b>- {t('tools.unavailable')}</b>
              </Typography>
            </>
          }
        >
          <StatusDot color="#43A047" />
          <Typography>
            {t('trucks.title')}{' '}
            <b>
              - {countTrucks} {t('tools.available')}
            </b>
          </Typography>
        </If>
      </Grid>
    </Grid>
  );
}
