export const handleChange =
  (
    panel: any,
    expanded,
    setExpanded,
    accordionStatus,
    setAccordionStatus,
    type,
  ) =>
  (event: any, newExpanded: any) => {
    let expandedCopy = { ...expanded };
    expandedCopy[panel] = newExpanded;
    setExpanded?.(expandedCopy);
    let accordionStatusCopy;
    if (Array.isArray(accordionStatus))
      accordionStatusCopy = [...accordionStatus];
    if (type === 'one_dropdown') {
      setAccordionStatus?.(accordionStatusCopy);
    } else {
      for (let j = 0; j < accordionStatusCopy.length; j++) {
        if (accordionStatusCopy[j].key != panel) continue;
        for (let i = 0; i < accordionStatusCopy[j].value.length; i++) {
          accordionStatusCopy[j].value[i] = false;
        }
      }
      setAccordionStatus?.(accordionStatusCopy);
    }
  };
