import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';

interface Props {
  accordionStatus: any;
  rowsGroupIndex: any;
  index: any;
  row: any;
  headCell: any;
}

export const RowDataValue: React.FC<Props> = ({
  accordionStatus,
  rowsGroupIndex,
  index,
  row,
  headCell,
}) => {
  const theme = useSelector(selectTheme);
  return (
    <>
      {accordionStatus?.[rowsGroupIndex]?.value[index] ? (
        <div style={{ color: theme.primary }}>
          {row.data[headCell.id]?.value}
        </div>
      ) : (
        <div style={{ color: themes?.default?.black }}>
          {row.data[headCell.id]?.value}
        </div>
      )}
    </>
  );
};
