import { Checkbox, TableCell } from '@mui/material';
import React from 'react';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from '../styles/accordion';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { StyledTableRow } from 'app/components/TableComponent/helpers/grid';
import { AccordionCellContent } from 'app/components/TableComponent/helpers/styles';
import { RowDataValue } from 'app/components/RoundTrips/components/RowDataValue';
import { themes } from 'styles/theme/themes';

export const TrackFunction = ({
  rows,
  isSelected,
  handleClickAccordionEl,
  selected,
  setSelected,
  headCells,
  handleAccordionToggle,
  accordionStatus,
  theme,
}) => {
  let tablesData: any = [];
  rows.map((rowsGroup, rowsGroupIndex) => {
    tablesData.push(
      rowsGroup.data.map((row, index) => {
        const isItemSelected = !!isSelected(rowsGroup.key, row.data.id.value);
        const labelId = `enhanced-table-checkbox-${index}`;
        let styles = {};
        if (isItemSelected) {
          styles = { backgroundColor: theme.primaryLightO };
        }

        return (
          <React.Fragment key={`${rowsGroup.key}-${row.data.id.value}`}>
            <StyledTableRow
              hover
              onClick={event => {
                handleClickAccordionEl(
                  event,
                  rowsGroup.key,
                  row.data.id.value,
                  selected,
                  setSelected,
                );
              }}
              role="checkbox"
              aria-checked={isItemSelected}
              tabIndex={-1}
              key={row.data.id.value}
              selected={isItemSelected}
              sx={{
                display: 'flex',
              }}
            >
              <TableCell
                padding="checkbox"
                style={{ ...styles }}
                sx={{
                  display: 'flex',
                  minWidth: '52px',
                  borderBottom: 'unset',
                  paddingTop: '30px !important',
                  // marginTop: '5px',
                }}
              >
                {isItemSelected}
                <Checkbox
                  color="primary"
                  checked={isItemSelected}
                  inputProps={{
                    'aria-labelledby': labelId,
                  }}
                  sx={{
                    height: 'fit-content',
                  }}
                />
              </TableCell>
              {headCells.map(headCell => {
                if (headCell.disableRow) return null;
                return (
                  <TableCell
                    key={headCell.id}
                    align="left"
                    style={{
                      ...styles,
                    }}
                    sx={{
                      display: 'flex',
                      alignItems: 'center',
                      cursor: 'pointer',
                      borderBottom: 'unset',
                      borderRight: `0.063rem solid ${theme.tableBorder}`,
                      paddingTop: '25px',
                      paddingBottom: '10px',
                      flex: 1,
                      minWidth: headCell.minWidth || 0,
                      maxWidth: headCell.maxWidth || 'unset',
                      overflow: 'hidden',
                    }}
                    onClick={e => {
                      if (headCell.isAccordion) {
                        handleAccordionToggle(e, rowsGroupIndex, index);
                      }
                    }}
                  >
                    {!headCell.isAccordion ? (
                      row.data[headCell.id]?.value
                    ) : (
                      <AccordionCellContent>
                        <RowDataValue
                          accordionStatus={accordionStatus}
                          rowsGroupIndex={rowsGroupIndex}
                          index={index}
                          row={row}
                          headCell={headCell}
                        />
                        {headCell.isAccordion &&
                          (accordionStatus?.[rowsGroupIndex]?.value[index] ? (
                            <ArrowDropUpIcon sx={{ color: theme.primary }} />
                          ) : (
                            <ArrowDropDownIcon
                              sx={{ color: themes?.default?.black }}
                            />
                          ))}
                      </AccordionCellContent>
                    )}
                  </TableCell>
                );
              })}
              {row.timeLine && (
                <TableCell
                  sx={{
                    borderBottom: 'unset',
                    display: 'flex',
                    width: '100%',
                    paddingTop: '25px',
                    ...styles,
                  }}
                >
                  {row.timeLine}
                </TableCell>
              )}
            </StyledTableRow>

            <Accordion
              expanded={
                accordionStatus?.[rowsGroupIndex]?.value[index] || false
              }
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                style={{ display: 'none' }}
              ></AccordionSummary>
              <AccordionDetails>{row.accordion}</AccordionDetails>
            </Accordion>
            <div
              style={{
                borderBottom: `0.063rem solid ${theme.tableBorder}`,
              }}
            ></div>
          </React.Fragment>
        );
      }),
    );
  });
  return tablesData;
};
