import {
  Button,
  Collapse,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Snackbar,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  useCreateTemplateMutation,
  useLazyGetOrdersIdQuery,
} from 'common/services/orderApi';
import { useUpdateCustomerOrderMutation } from 'common/services/orderApi';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import styled from 'styled-components';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { StatusChips } from '../Orders/components/StatusChips';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import moment from 'moment';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { ProductRowLayoutSales } from 'app/components/ProductRowLayout/sales';
import { useTranslation } from 'react-i18next';
import { ActionPopover } from 'app/components/Popover';
import { OrderDetailsDrawer } from '../Orders/components/OrderDetailsDrawer';
import TemplateDialog from '../Orders/components/OrderTemplate';
import MuiAlert from '@mui/material/Alert';
import CheckCircleOutlined from '@mui/icons-material/CheckCircleOutlined';
import DoubleArrowOutlinedIcon from '@mui/icons-material/DoubleArrowOutlined';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import { Check } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { ORDERS_ACTIONS } from 'utils/routes';
import { ProductDetailsDrawer } from '../Orders/components/ProductDetailsDrawer';
import BoltIcon from '@mui/icons-material/Bolt';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { InsideOrderEllipsisSales } from '../Orders/components/EllipsisSales/InsideOrderEllipsisSales';
import { useSelector } from 'react-redux';
import { selectAuthUser } from 'app/slices/auth/selectors';
import CancelOrderDialog from '../Orders/components/CancelOrder';

const drawerWidth = 453;

export interface ToastMessage {
  message: string;
  key: number;
  severity: 'error' | 'warning' | 'info' | 'success';
}

export function SalesOrderDetails() {
  const { id } = useParams();
  const [orderIdState, setOrderIdState] = useState<string>('');
  const [orderDiscount, setOrderDiscount] = useState<number | null>(0);
  const [orderStatus, setOrderStatus] = useState('');
  // const [widthupdate, setWidthupdate] = useState('maxWidth');
  const authUser = useSelector(selectAuthUser);

  const [selectedProduct, setSelectedProduct] = useState<Array<any>>([]);
  const [isProductDetailsOpen, setIsProductDetailsOpen] =
    useState<boolean>(false);
  const handleProductDetails = product => {
    setIsProductDetailsOpen(true);
    setSelectedProduct(product);
  };

  const [isCanceled, setIsCanceled] = useState<boolean>(false);

  const navigate = useNavigate();

  useEffect(() => {
    if (id) {
      setOrderIdState(id);
      triggerGetOrder(id);
    }
  }, [id, orderIdState, isCanceled]);
  // useEffect(() => {
  //   console.log('hello world');

  //   if (orderStatus === 'pending' || orderStatus === 'validated') {
  //     setWidthupdate('1200px');
  //   } else {
  //     setWidthupdate('maxcontent');
  //   }
  // });
  const { t } = useTranslation();
  const [openToast, setOpenToast] = useState(false);
  const [snackPack, setSnackPack] = useState<readonly ToastMessage[]>([]);
  const toast = (message: string, severity: ToastMessage['severity']) => {
    setSnackPack(prev => [
      ...prev,
      { message, key: new Date().getTime(), severity },
    ]);
  };
  const [height, setHeight] = useState('');
  let ordc;
  const handleProductListHeight = () => {
    ordc = document.querySelector('.ordc');
    setHeight(ordc?.clientHeight.toString() + 'px');
  };
  const handleToastClose = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpenToast(false);
  };

  const [triggerGetOrder, { data: dataOrder, isFetching }] =
    useLazyGetOrdersIdQuery();

  const [button, setButton] = useState<string>('');

  const [selectedCategory, setSelectedCategory] = useState<Array<string>>([]);
  const [orderedProducts, setOrderedProducts] = useState<any[]>([]);
  const [categoryCollapseStates, setCategoryCollapseStates] = useState(
    selectedCategory.map(() => true),
  );
  const [subtotal, setSubtotal] = useState<number>(0);
  const [totalTTC, setTotalTTC] = useState<number>(0);
  const [salesTax, setSalesTax] = useState<number>(0);

  const [isOrderDetailsOpen, setIsOrderDetailsOpen] = useState<boolean>(false);
  const [orderDetailsTab, setOrderDetailsTab] = useState<string>('1');

  const [isTemplateDialogOpen, setIsTemplateDialogOpen] =
    useState<boolean>(false);
  const [templateToast, setTemplateToast] = useState<boolean>(false);
  const [initialProductsState, setInitialProductsState] = useState<any>([]);

  useEffect(() => {
    if (dataOrder && dataOrder.products) {
      const newArray = dataOrder.products.map(e => {
        const modifiedProduct = {
          ...e._product,
          boxPrice: e.salePrice,
        };

        return {
          id: e._product._id,
          product: modifiedProduct,
          quantity: e.quantity,
          approvedQuantity: e.approvedQuantity,
          discount: e.discount,
          initialDiscount: e.initialDiscount,
          salePrice: e.salePrice,
          amount: e.amount,
        };
      });
      const uniqueCategoryNames = dataOrder.products.map(
        item => item._product.cache?._category._parentCategory.name,
      );
      setSelectedCategory([...uniqueCategoryNames]);
      setOrderedProducts([...newArray]);

      setInitialProductsState([...newArray]);

      // Calculate the subtotal
      let subtotal = newArray.reduce((acc, item) => {
        return (
          acc +
          item.salePrice * item.approvedQuantity -
          item.salePrice * item.approvedQuantity * ((item.discount || 0) / 100)
        );
      }, 0);

      // Calculate total after discount
      let totalAfterDiscount = subtotal - subtotal * (dataOrder.discount / 100);

      // Calculate sales tax
      let salesTax = totalAfterDiscount * 0.055;

      // Calculate total amount including tax
      let totalTTC = totalAfterDiscount + salesTax;

      setSubtotal(subtotal);
      setSalesTax(salesTax);
      setTotalTTC(totalTTC);
      setOrderDiscount(dataOrder.discount);

      setOrderStatus(dataOrder?.status);

      if (dataOrder.status == 'pending-customer') {
        setButton('');
      }
    }
  }, [dataOrder]);

  useEffect(() => {
    if (dataOrder) {
      updateOrderStatus();
    }
  }, [initialProductsState]);

  // This function compares the current product state to the initial state
  const hasOrderChanged = () => {
    return orderedProducts.some((product, index) => {
      const initialProduct = initialProductsState[index];
      if (product.approvedQuantity !== -1) {
        return product.approvedQuantity !== initialProduct.quantity;
      }
    });
    // }
  };
  // useEffect(() => {
  //   onscroll();
  // });
  const updateOrderStatus = () => {
    setButton(hasOrderChanged() ? 'send back' : 'validate');
  };

  useEffect(() => {
    setCategoryCollapseStates(selectedCategory.map(() => true));
  }, [selectedCategory]);

  function groupProductsByCategory(orderedProduct, selectedCategory) {
    const groupedProducts = {};

    selectedCategory.forEach(category => {
      groupedProducts[category] = [];
    });

    orderedProduct.forEach((productId, index) => {
      const productCategory = selectedCategory[index];
      if (productCategory) {
        groupedProducts[productCategory].push(productId);
      }
    });

    return groupedProducts;
  }

  const groupedProducts = groupProductsByCategory(
    orderedProducts,
    selectedCategory,
  );

  const toggleCategoryCollapse = index => {
    const updatedStates = [...categoryCollapseStates];
    updatedStates[index] = !updatedStates[index];
    setCategoryCollapseStates(updatedStates);
  };

  function countCategoryOccurrences(categoryName, selectedCategory) {
    let count = 0;
    for (const category of selectedCategory) {
      if (category === categoryName) {
        count++;
      }
    }
    return count;
  }

  const [createTemplate] = useCreateTemplateMutation();

  const handleSaveTemplate = async formData => {
    try {
      await createTemplate({
        formData: {
          orderId: orderIdState,
          name: formData.name,
          description: formData.note,
          weekDays: formData.days,
        },
      }).unwrap();
      setTemplateToast(true);
    } catch (error) {
      console.log('Something went worng!');
    }
  };

  const handleTemplateDialogClose = () => {
    setIsTemplateDialogOpen(false);
  };

  const handleTemplateToastClose = () => {
    setTemplateToast(false);
  };

  const [updateOrder] = useUpdateCustomerOrderMutation();
  const [action, setAction] = useState('');

  const saveOrder = async (status = '') => {
    let newOrderedProducts: any = [];

    if (orderedProducts.length > 0) {
      newOrderedProducts = orderedProducts?.map(item => ({
        _product: item.id,
        quantity: item.quantity,
        approvedQuantity: item.approvedQuantity,
        salePrice: item.salePrice,
        discount: item.discount === null ? 0 : item.discount,
      }));
    }

    type UpdateOrderFormData = {
      products: any[];
      discount: number | null;
      status?: string;
      customerId: string;
    };

    try {
      let formData: UpdateOrderFormData = {
        products: newOrderedProducts,
        discount: orderDiscount,
        customerId: dataOrder._customer._id,
      };

      if (status) {
        formData.status = status;
      }

      await updateOrder({
        id: orderIdState,
        formData: formData,
      })
        .unwrap()
        .then(data => {
          // Calculate the subtotal
          let subtotal = newOrderedProducts.reduce((acc, item) => {
            return (
              acc +
              item.salePrice * item.approvedQuantity -
              item.salePrice *
                item.approvedQuantity *
                ((item.discount || 0) / 100)
            );
          }, 0);

          // Calculate total after discount
          let totalAfterDiscount =
            subtotal - subtotal * ((orderDiscount || 0) / 100);

          // Calculate sales tax
          let salesTax = totalAfterDiscount * 0.055;

          // Calculate total amount including tax
          let totalTTC = totalAfterDiscount + salesTax;

          setSubtotal(subtotal);
          setSalesTax(salesTax);
          setTotalTTC(totalTTC);

          setOrderStatus(data.status);

          // setOrderDiscount(data.discount);
          if (status == 'pending-customer' || status == 'validated') {
            navigate(ORDERS_ACTIONS);
          }
        });
    } catch (error) {
      // toast(t(' messages.warehouse.status.error'), 'error');
    }
  };

  useEffect(() => {
    if (action) {
      updateOrderStatus();
      saveOrder();
    }
  }, [orderedProducts, orderDiscount]);

  const handleProductOrdered = product => {
    setOrderedProducts(prevOrderedProducts => {
      const existingProductIndex = prevOrderedProducts.findIndex(
        item => item.id === product.id,
      );
      if (existingProductIndex !== -1) {
        // Replace the existing product with the updated one
        const updatedOrderedProducts = [...prevOrderedProducts];
        updatedOrderedProducts[existingProductIndex] = product;
        return updatedOrderedProducts;
      } else {
        // Add the new product to the list
        return [...prevOrderedProducts, product];
      }
    });
  };

  const [isDeleteOrderDialogOpen, setIsDeleteOrderDialogOpen] =
    useState<boolean>(false);
  ///Order Info Action
  const [selectedOrder, setSelectedOrder] = useState<Object[]>([]);

  const handleAddNote = order => {
    setSelectedOrder(order);
    setIsOrderDetailsOpen(true);
    setOrderDetailsTab('3');
  };

  const handleDeleteOrderDialogOpen = () => {
    setIsDeleteOrderDialogOpen(true);
  };

  function handleOrderDetailsDrawerOpen(order) {
    setSelectedOrder(order);
    setIsOrderDetailsOpen(true);
    setOrderDetailsTab('1');
  }

  const [isCancelOrderDialogOpen, setIsCancelOrderDialogOpen] =
    useState<boolean>(false);
  const [selectedCancelOrder, setSelectedCancelOrder] = useState<any>();

  const handleCancelOrderDialogOpen = order => {
    setSelectedCancelOrder(order);
    setIsCancelOrderDialogOpen(true);
  };

  const handleCancelOrderDialogClose = () => {
    setIsCancelOrderDialogOpen(false);
  };

  const handleCancelOrder = async () => {
    try {
      await updateOrder({
        id: selectedCancelOrder?.id,
        formData: { status: 'canceled' },
      }).unwrap();
      setIsCanceled(true);
      setIsCancelOrderDialogOpen(false);
    } catch (error) {
      toast(t(' messages.warehouse.status.error'), 'error');
    }
  };

  return (
    <Wrapper>
      <Snackbar
        open={isCanceled}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleCancelOrderDialogClose}
      >
        <MuiAlert
          onClose={handleCancelOrderDialogClose}
          elevation={6}
          variant="filled"
          severity="success"
          icon={
            <CheckCircleOutlined style={{ color: 'rgba(46, 125, 50, 1)' }} />
          }
          sx={{
            color: 'rgba(18, 50, 20, 1)',
            backgroundColor: 'rgba(234, 242, 234, 1)',
            boxShadow: 'none',
          }}
        >
          {isCanceled && t('orders.status.order_successfully_canceled')}
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={openToast}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleToastClose}
      >
        <MuiAlert
          onClose={handleToastClose}
          elevation={6}
          variant="filled"
          severity="success"
          icon={
            <CheckCircleOutlined style={{ color: 'rgba(46, 125, 50, 1)' }} />
          }
          sx={{
            color: 'rgba(18, 50, 20, 1)',
            backgroundColor: 'rgba(234, 242, 234, 1)',
            boxShadow: 'none',
          }}
        >
          Order is pending updates from client
        </MuiAlert>
      </Snackbar>

      <Snackbar
        open={templateToast}
        autoHideDuration={5000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        onClose={handleTemplateToastClose}
      >
        <MuiAlert
          onClose={handleTemplateToastClose}
          elevation={6}
          variant="filled"
          severity="success"
          icon={
            <CheckCircleOutlined style={{ color: 'rgba(46, 125, 50, 1)' }} />
          }
          sx={{
            color: 'rgba(18, 50, 20, 1)',
            backgroundColor: 'rgba(234, 242, 234, 1)',
            boxShadow: 'none',
          }}
        >
          {t('orders.template.status.saved')}
        </MuiAlert>
      </Snackbar>

      <Drawer
        anchor="right"
        open={isOrderDetailsOpen}
        onClose={() => setIsOrderDetailsOpen(false)}
      >
        <DrawerWrapper>
          <OrderDetailsDrawer
            closeDrawer={() => setIsOrderDetailsOpen(false)}
            order={dataOrder}
            defaultTab={orderDetailsTab}
          />
        </DrawerWrapper>
      </Drawer>
      <TemplateDialog
        open={isTemplateDialogOpen}
        onClose={handleTemplateDialogClose}
        onSaveTemplate={handleSaveTemplate}
      />
      <Grid container alignItems="center">
        <IconButton onClick={() => window.history.back()}>
          <ArrowBackIcon />
        </IconButton>

        {dataOrder?.type === 'collection' ? (
          <Tooltip title={t('common.collection')}>
            <DoubleArrowOutlinedIcon
              sx={{
                width: '16px',
                height: '16px',
                color: '#6B8BFE',
                transform: 'rotate(180deg)',
                mr: '6px',
              }}
            />
          </Tooltip>
        ) : dataOrder?.type === 'delivery' ? (
          <Tooltip title={t('delivery')}>
            <DoubleArrowOutlinedIcon
              sx={{
                width: '16px',
                height: '16px',
                color: '#ADB9BE',
                mr: '6px',
              }}
            />
          </Tooltip>
        ) : dataOrder?.type === 'express' ? (
          <Tooltip title={t('common.express')}>
            <BoltIcon
              sx={{
                width: '16px',
                height: '16px',
                color: '#F9A825',
                mr: '6px',
              }}
            />
          </Tooltip>
        ) : dataOrder?.type === 'repair' ? (
          <Tooltip title={t('repair')}>
            <PriorityHighIcon
              sx={{
                width: '16px',
                height: '16px',
                color: '#C62828',
                mr: '6px',
              }}
            />
          </Tooltip>
        ) : null}
        <Typography
          fontSize="1.5rem"
          fontWeight={400}
          sx={{
            mr: '14px',
          }}
        >
          Order #{dataOrder?.code}
        </Typography>
        <StatusChips
          status={orderStatus}
          showTooltip={false}
          tooltipUneditable={true}
        />
        <div
          className="line"
          style={{
            width: '1px',
            height: '20px',
            backgroundColor: '#E0E0E0',
            marginLeft: '12px',
            marginRight: '12px',
          }}
        ></div>
        {dataOrder?._createdBy?._id === authUser?._id ? (
          <Typography
            fontSize="0.625rem"
            fontWeight={400}
            sx={{
              color: '#00000099',
              backgroundColor: '#f3f4f5',
              padding: '5px 10px',
              border: '1px solid #EEEEEE',
              borderRadius: '4px',
            }}
          >
            {t('order.created_by')} {dataOrder?._createdBy?.firstName}
          </Typography>
        ) : (
          <Typography
            fontSize="0.625rem"
            fontWeight={400}
            sx={{
              color: '#00000099',
            }}
          >
            {t('order.created_by')} {dataOrder?._createdBy?.fullName}
          </Typography>
        )}

        <RightHeaderSection>
          {orderStatus !== 'pending-customer' && (
            <>
              {button == 'validate' && (
                <Button
                  variant="contained"
                  startIcon={<Check />}
                  sx={{
                    borderRadius: '42px',
                    mr: '12px',
                  }}
                  onClick={() => {
                    setAction('validate');
                    saveOrder('validated');
                  }}
                >
                  Validate
                </Button>
              )}
              {button == 'send back' && (
                <Button
                  variant="contained"
                  // disabled={orderActiveAlert}
                  startIcon={<Check />}
                  sx={{
                    borderRadius: '42px',
                    mr: '12px',
                  }}
                  onClick={() => {
                    setAction('send back');
                    saveOrder('pending-customer');
                  }}
                >
                  Send Back
                </Button>
              )}
            </>
          )}
          <InsideOrderEllipsisSales
            status={dataOrder?.status}
            createdBySales={dataOrder?.createdBySales}
            orderId={dataOrder?._id}
            handleOrderDetailsDrawerOpen={handleOrderDetailsDrawerOpen}
            handleDeleteOrderDialogOpen={handleDeleteOrderDialogOpen}
            handleSaveAsTemplate={() => setIsTemplateDialogOpen(true)}
            handleAddNote={() => handleAddNote(dataOrder)}
            handleCancelOrderDialogOpen={() =>
              handleCancelOrderDialogOpen(dataOrder)
            }
          />
        </RightHeaderSection>
      </Grid>
      <Grid
        container
        alignItems="center"
        sx={{
          mt: '32px',
        }}
      >
        <Typography
          fontWeight={500}
          fontSize="0.875rem"
          sx={{
            ml: '2px',
          }}
        >
          {dataOrder?._deck?.location?.name}
        </Typography>
        <div
          className="vector"
          style={{
            border: '0.5px rgba(0, 0, 0, 0.23) solid',
            height: '16px',
            marginLeft: '8px',
            marginRight: '8px',
          }}
        ></div>
        <CalendarTodayIcon
          style={{ fontSize: '1.1rem', color: 'rgba(129, 145, 152, 1)' }}
        />
        <Typography
          fontWeight={500}
          fontSize="0.875rem"
          sx={{
            ml: '7px',
          }}
        >
          {moment(dataOrder?.deliveryDate).format('MMMM D, YYYY')}
        </Typography>
      </Grid>
      <div
        className="productList"
        onScroll={() => console.log('Button clicked!')}
        onClick={() => console.log('Button clicked!')}
        onLoad={handleProductListHeight}
        style={{
          maxHeight: 'calc(100% - 117px)',
          width: 'calc(100% - -20px)',
          minWidth: '1200px',
        }}
      >
        <div
          className="headerContainer"
          style={{
            backgroundColor: '#EEE',
            minWidth: '990px',
            position: 'sticky',
            top: '0px',
            zIndex: '999',
          }}
        >
          <TableCell
            sx={{
              height: '36px',
              width: '310px',
              padding: '8px',
              position: 'sticky',
              backgroundColor: '#EEE',
              left: '0px',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Items by category
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Ordered Qty
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Available Qty
            </Typography>
          </TableCell>
          {/* <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Stock Qty
            </Typography>
          </TableCell> */}
          <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Price
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Discount R3
            </Typography>
          </TableCell>
          <TableCell
            sx={{
              height: '36px',
              width: '11rem',
              padding: '0',
            }}
          >
            <Typography fontSize="1rem" fontWeight="500">
              Total HT
            </Typography>
          </TableCell>
        </div>
        <OrderDetailsCommande className="ordc">
          <LineContainer>
            <Grid container alignItems="center">
              <Typography>{t('order.itemsList.subtotal')}</Typography>
              <Typography
                sx={{
                  ml: 'auto',
                }}
              >
                € {subtotal.toFixed(2)}
              </Typography>
            </Grid>
          </LineContainer>
          <LineContainer>
            <Grid container alignItems="center">
              <Typography>Add Discount</Typography>
              <Typography
                sx={{
                  ml: 'auto',
                }}
              >
                %
                <FormControl
                  sx={{
                    width: '7ch',
                    marginLeft: '10px',
                    marginBottom: '0 !important',
                    backgroundColor: 'rgba(238, 238, 238, 1)',
                  }}
                  variant="outlined"
                >
                  <OutlinedInput
                    id="outlined-adornment-discount"
                    aria-describedby="outlined-discount-helper-text"
                    onChange={e => {
                      if (orderStatus == 'pending-customer') {
                        return false;
                      }
                      const newDiscountValue = e.target.value;
                      if (newDiscountValue === '') {
                        setOrderDiscount(null);
                        setAction('update order discount');
                      } else {
                        const newDiscount = parseFloat(newDiscountValue);
                        if (
                          !isNaN(newDiscount) &&
                          newDiscount >= 0 &&
                          newDiscount <= 100
                        ) {
                          setOrderDiscount(newDiscount);
                          setAction('update order discount');
                        }
                      }
                    }}
                    value={orderDiscount}
                    size="small"
                    sx={{
                      '& .MuiInputBase-input': {
                        // This targets the input element within the OutlinedInput component
                        padding: '0 4px !important', // Set your desired padding here
                      },
                    }}
                    inputProps={{
                      min: 0,
                      max: 100,
                      type: 'number',
                    }}
                    disabled={orderStatus === 'pending-customer'}
                  />
                </FormControl>
              </Typography>
            </Grid>
          </LineContainer>
          <LineContainer>
            <Grid container alignItems="center">
              <Typography>{t('order.itemsList.tax')} 0.055%</Typography>
              <Typography
                sx={{
                  ml: 'auto',
                }}
              >
                € {salesTax.toFixed(2)}
              </Typography>
            </Grid>
          </LineContainer>
          <LastLineContainer>
            <Grid container alignItems="center">
              <Typography>Total TTC</Typography>
              <Typography
                sx={{
                  ml: 'auto',
                }}
              >
                € {totalTTC.toFixed(2)}
              </Typography>
            </Grid>
          </LastLineContainer>
        </OrderDetailsCommande>
        <div
          className="fakeContainer"
          id="fakeContainer"
          style={{
            width: '1px',
            height: height,
            float: 'right',
            marginTop: '20px',
            position: 'sticky',
            bottom: '10px',
            marginRight: '10px',
            zIndex: '-1',
            visibility: 'hidden',
          }}
        ></div>
      </div>
      <div
        className="productWrapper"
        style={{
          overflow: 'auto',
          maxHeight: '60%',
          minWidth: '1200px',
        }}
      >
        {selectedCategory
          ?.filter((item, index) => selectedCategory.indexOf(item) === index)
          .map((categoryName, index) => (
            <>
              <Button
                onClick={() => toggleCategoryCollapse(index)}
                sx={{
                  mt: '20px',
                  mb: '20px',
                  backgroundColor: '#B682FF',
                  color: 'white',
                  ':hover': {
                    backgroundColor: '#954ff7',
                  },
                }}
                startIcon={
                  categoryCollapseStates[index] ? (
                    <KeyboardArrowDownIcon />
                  ) : (
                    <KeyboardArrowRightIcon />
                  )
                }
              >
                <Grid container alignItems="center">
                  <Typography
                    fontSize="1rem"
                    fontWeight="600"
                    sx={{
                      mr: '8px',
                    }}
                  >
                    {categoryName}
                  </Typography>
                  <Typography fontSize="0.857rem" fontWeight="400">
                    {countCategoryOccurrences(categoryName, selectedCategory)}
                  </Typography>
                </Grid>
              </Button>
              <Collapse in={categoryCollapseStates[index]}>
                {groupedProducts[categoryName]?.map(e => {
                  const quantityIn =
                    orderedProducts.find(m => m.id === e.id)?.quantity || 1;

                  let approvedQuantityIn;
                  const foundProduct = orderedProducts.find(m => m.id === e.id);
                  if (foundProduct?.approvedQuantity === -1) {
                    approvedQuantityIn = quantityIn;
                  } else if (foundProduct?.approvedQuantity !== undefined) {
                    approvedQuantityIn = foundProduct.approvedQuantity;
                  } else {
                    approvedQuantityIn = 1;
                  }

                  return (
                    <ProductRowLayoutSales
                      data={e}
                      onProductOrdered={handleProductOrdered}
                      quantityIn={quantityIn}
                      approvedQuantityIn={approvedQuantityIn}
                      discountIn={
                        orderedProducts.find(m => m.id === e.id)?.discount || 0
                      }
                      initialDiscountIn={
                        orderedProducts.find(m => m.id === e.id)
                          ?.initialDiscount || 0
                      }
                      setAction={setAction}
                      orderStatus={orderStatus}
                      handleProductDetails={handleProductDetails}
                    />
                  );
                })}
              </Collapse>
            </>
          ))}
      </div>

      <CancelOrderDialog
        open={isCancelOrderDialogOpen}
        onClose={handleCancelOrderDialogClose}
        onCancelOrder={handleCancelOrder}
        orderCode={selectedCancelOrder?.code}
        date={selectedCancelOrder?.deliveryDate}
        branch={selectedCancelOrder?.cache?._deck}
      />

      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: drawerWidth,
          },
        }}
        anchor="right"
        open={isProductDetailsOpen}
        onClose={() => setIsProductDetailsOpen(false)}
      >
        <ProductDetailsDrawer
          product={selectedProduct}
          closeDrawer={() => setIsProductDetailsOpen(false)}
        />
      </Drawer>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
  width: 100%;
`;

const OrderDetailsCommande = styled.div`
  height: max-content;
  width: 332px;
  background-color: red;
  margin-top: 20px;
  border-radius: 4px;
  border: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
  background: #fff;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 99;
`;

const LineContainer = styled.div`
  padding: 6px 16px;
  height: 32px;
  width: 100%;
  border-bottom: 1px solid var(--divider, rgba(0, 0, 0, 0.12));
`;

const LastLineContainer = styled.div`
  padding: 6px 16px;
  height: 32px;
  width: 100%;
  background-color: rgb(245, 245, 245);
`;

const DrawerWrapper = styled.div`
  width: 775px;
  height: 100%;
`;

const RightHeaderSection = styled.div`
  margin-left: auto;
  display: flex;
`;
