import { Data } from 'interfaces/roundTrips';
interface HeadCell {
  disablePadding: boolean;
  id: keyof Data;
  label: string;
  numeric: boolean;
  disableSort?: boolean;
  disableRow?: boolean;
  canChangeView?: boolean;
  size: number;
  isAccordion: boolean;
  minWidth?: number;
  maxWidth?: number;
}
export const stateHeadCellsConst: HeadCell[] = [
  {
    id: 'key_point',
    numeric: false,
    disablePadding: false,
    label: '',
    disableRow: false,
    canChangeView: false,
    size: 1,
    isAccordion: false,
    disableSort: false,
    minWidth: 300,
    maxWidth: 300,
  },
];
