import { PopoverEl } from 'app/components/Popover';
import {
  StatusIndicator,
  PulsingIndicator,
} from 'app/components/RoundtripStatus';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { themes } from 'styles/theme/themes';

interface IObject {
  [key: string]: any;
}

interface Props {
  action: any;
  title: any;
  color: string;
  pulse?: IObject;
}
export const stylingComponent = {
  display: 'flex',
  alignItems: 'center',
  gap: '10px',
  width: '100%',
  paddingLeft: themes.default?.popoverLabelPadding,
  paddingRight: themes.default?.popoverLabelPadding,
};

export const ChangeStatusTab: React.FC<Props> = ({
  action,
  title,
  color,
  pulse,
}) => {
  const theme = useSelector(selectTheme);
  const { t } = useTranslation();

  return (
    <div style={stylingComponent} onClick={action}>
      {pulse ? (
        <PulsingIndicator
          color={color}
          baseboxShadow={pulse.boxShadow}
          animationBoxShadow={pulse.animationBoxShadow}
        />
      ) : (
        <StatusIndicator color={color} />
      )}
      <PopoverEl redStatus={theme.redStatus} style={{ flexGrow: '1' }}>
        {t(title)}
      </PopoverEl>
    </div>
  );
};
