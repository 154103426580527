import * as React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { formatDistance } from 'common/helpers/place';
import { formatDuration } from 'common/utils/dates';
import { Icon } from 'app/components/Icon';
import { MapPin, Timer } from '@phosphor-icons/react';
import If from 'app/components/If';

interface Props {
  distance?: number;
  duration?: number;
}

export const RouteData: React.FC<Props> = ({ distance, duration }) => {
  const { t } = useTranslation();

  return (
    <ContainerCollected>
      <If condition={!!distance || distance === 0}>
        <div className="row">
          <Icon icon={<MapPin />} tooltip={t('roundtrips.tooltip.distance')} />
          <p>{formatDistance(distance || 0)}</p>
        </div>
      </If>
      <If condition={!!duration || duration === 0}>
        <div className="row">
          <Icon icon={<Timer />} tooltip={t('roundtrips.tooltip.time')} />
          <p>{formatDuration(duration || 0)}</p>
        </div>
      </If>
    </ContainerCollected>
  );
};

export const ContainerCollected = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  flex-direction: column;
  .row {
    height: 20px;
    display: flex;
    gap: 6px;
    align-items: center;
  }
`;
