import styled from 'styled-components';
import { themes } from 'styles/theme/themes';

export const SelectAllWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const WrapperFile = styled.div`
  margin: 28px auto;
  background-color: ${themes?.default?.accordionWhiteBg};
  padding: 5px;
  max-height: 254px;
  overflow: auto;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 50px;

  & > *:not(:last-child) {
    margin-right: 50px;
  }
`;

export const SupportWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const SupportNumberWrapper = styled.div`
  text-align: end;
  padding-right: 5px;
`;
