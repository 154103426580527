import { createSelector } from '@reduxjs/toolkit';

import { initialState } from '.';
import { RootState } from '../../types/State';

const selectSlice = (state: RootState) => state.filters || initialState;
export const selectOrderFilters = createSelector(
  [selectSlice],
  state => state.orderFilters,
);
